import React, { forwardRef } from 'react'
import { Backdrop, Box, makeStyles } from '@material-ui/core'
import loader from 'assets/img/loader.gif'

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 9999,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    height: '100%',
    width: '100%',
  },
}))

const PictoLoader = forwardRef(
  ({ backdrop = true, height = 100, width = 100, className = '' }, ref) => {
    const classes = useStyles()
    return backdrop ? (
      <Backdrop className={classes.backdrop} open>
        <Box ref={ref} className={className} height={height} width={width}>
          <img className={classes.img} alt="" src={loader} />
        </Box>
      </Backdrop>
    ) : (
      <Box ref={ref} className={className} height={height} width={width}>
        <img className={classes.img} alt="" src={loader} />
      </Box>
    )
  },
)

export default PictoLoader
