/* eslint-disable camelcase */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Navbar from 'components/Navbar'
import AuditStart from 'components/AuditStart'
import PictoLoader from 'components/PictoLoader'

const Start = (props) => {
  const { audit } = props
  const { t } = useTranslation('routes')

  return (
    <>
      <Navbar name={t('routes:audit.start.name')} />
      <PictoLoader />
      <AuditStart audit={audit} />
    </>
  )
}

const mapStateToProps = (state) => ({
  audit: state.control.audit,
})

export default connect(mapStateToProps, null)(Start)
