/* eslint-disable camelcase */
import React from 'react'
import { useTranslation } from 'react-i18next'
import Navbar from 'components/Navbar'
import { ReferentialForm } from 'services'

const Referential = ({
  match: {
    params: { audit_id },
  },
}) => {
  const { t } = useTranslation('routes')

  return (
    <>
      <Navbar name={t('routes:audit.edit.referential.name')} />
      <ReferentialForm audit_id={audit_id} />
    </>
  )
}

export default Referential
