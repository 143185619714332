/* eslint-disable camelcase */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { push as pushAction } from 'connected-react-router'
import { Paper, Grid, Box, Typography, Input, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useApiContext } from 'contexts/api'
import { popSnackbar as popSnackbarAction } from 'actions/Control'
import * as AuditAPI from 'api/audit'
import { onlyOnline } from 'wrappers'
import AuditStatusStepper from '../AuditStatusStepper'
import AuditSubmitButtonBack from './AuditSubmitButtonBack'
import AuditMissingFieldsPanel from './AuditMissingFieldsPanel'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  input: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  submitText: {
    marginTop: theme.spacing(2),
  },
}))

const AuditSubmitText = () => {
  const { t } = useTranslation('atoms')
  const classes = useStyles()

  return (
    <Box className={classes.submitText}>
      <Typography variant="body1" component="p">
        {t('atoms:AuditSubmit.AuditSubmitText.text_1')}
      </Typography>
      <Typography variant="body1" component="p">
        {t('atoms:AuditSubmit.AuditSubmitText.text_2')}
      </Typography>
      <br />
      <Typography variant="body1" component="p">
        {t('atoms:AuditSubmit.AuditSubmitText.text_3')}
      </Typography>
    </Box>
  )
}
const AuditSubmit = ({ audit, popSnackbar, push }) => {
  const { t } = useTranslation('atoms')
  const classes = useStyles()
  const [submitter, setSubmitter] = useState('')
  const { apiCall } = useApiContext()

  const handleSubmitterChange = (event) => setSubmitter(event.target.value)

  const handleSubmit = () => {
    const onSuccess = () => {
      popSnackbar(t('atoms:AuditSubmit.handleSubmit.onSuccess'), 'success')

      push('/audits/all')
    }
    const onFailure = () => {
      popSnackbar(t('commons:notice.error'), 'error')
    }

    const body = {
      audit: {
        submitted_by: submitter,
      },
    }

    apiCall({
      request: AuditAPI.submitAudit(audit.id),
      onSuccess,
      onFailure,
      body,
    })

    setSubmitter('')
  }

  return (
    <Paper className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <AuditStatusStepper audit={audit} />
        </Grid>
        <Grid item xs={12}>
          <AuditMissingFieldsPanel audit={audit} />
        </Grid>
        <Grid item xs={12}>
          <AuditSubmitText />
        </Grid>
        <Grid item xs={12}>
          <Input
            className={classes.input}
            onChange={(e) => handleSubmitterChange(e)}
            placeholder={t('atoms:AuditSubmit.inputLabel')}
            inputProps={{
              'aria-label': t('atoms:AuditSubmit.inputLabel'),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AuditSubmitButtonBack audit_id={audit.id} />
          <Button
            className={classes.button}
            onClick={handleSubmit}
            disabled={submitter === ''}
            color="primary"
            variant="contained"
          >
            {t('atoms:AuditSubmit.button')}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

const mapDispatchToProps = (dispatch) => ({
  push: (url) => dispatch(pushAction(url)),
  popSnackbar: (msg, type) => dispatch(popSnackbarAction(msg, type)),
})

export default connect(null, mapDispatchToProps)(onlyOnline(AuditSubmit))
