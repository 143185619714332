/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as Control from 'actions/Control'
import * as AuditAPI from 'api/audit'
import { useApiContext } from 'contexts/api'

const mapDispatchToProps = (dispatch) => ({
  setAudit: (audit) => dispatch(Control.setAudit(audit)),
  popSnackbar: (message, type) => dispatch(Control.popSnackbar(message, type)),
})

const withAuditAuthorization = (WrappedComponent) => {
  const Wrapper = (props) => {
    const {
      match: {
        params: { audit_id },
      },
      setAudit,
      popSnackbar,
    } = props

    const { apiCall } = useApiContext()

    const [state, setState] = useState({
      currentAuditId: null,
      pendingAuditAuthorization: true,
      auditAuthorized: false,
      loadingAuditAuthorization: true,
    })

    const {
      currentAuditId,
      pendingAuditAuthorization,
      loadingAuditAuthorization,
      auditAuthorized,
    } = state

    useEffect(() => {
      const updateControlAudit = () => {
        const willUpdateAudit = audit_id !== null && audit_id !== currentAuditId

        if (willUpdateAudit) {
          const success = (data) => {
            setAudit(data)
            setState((prevState) => ({
              ...prevState,
              pendingAuditAuthorization: false,
              auditAuthorized: true,
              loadingAuditAuthorization: false,
            }))
          }

          const failure = () => {
            setState((prevState) => ({
              ...prevState,
              pendingAuditAuthorization: false,
              auditAuthorized: false,
              loadingAuditAuthorization: false,
            }))
            popSnackbar(
              "Une erreur est survenue lors du chargement de l'audit",
              'error',
            )
          }

          const unauthorized = () => {
            setState((prevState) => ({
              ...prevState,
              pendingAuditAuthorization: false,
              auditAuthorized: false,
              loadingAuditAuthorization: false,
            }))
            popSnackbar(
              "Vous n'êtes pas autorisé à consulter cet audit",
              'warning',
            )
          }

          apiCall({
            request: AuditAPI.getAudit(audit_id),
            onSuccess: success,
            onFailure: failure,
            onUnauthorized: unauthorized,
          })

          setState((prevState) => ({ ...prevState, currentAuditId: audit_id }))
        }
      }

      updateControlAudit()

      return () => setAudit({})
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audit_id])

    return (
      <WrappedComponent
        {...props}
        pendingAuditAuthorization={pendingAuditAuthorization}
        auditAuthorized={auditAuthorized}
        loadingAuditAuthorization={loadingAuditAuthorization}
      />
    )
  }

  const connectedWrapper = connect(null, mapDispatchToProps)(Wrapper)

  return connectedWrapper
}

export default withAuditAuthorization
