import React from 'react'
import { connect } from 'react-redux'
import { Switch, Redirect } from 'react-router-dom'
import PrivateRoute from 'components/PrivateRoute'
import General from './routes/General'
import Referential from './routes/Referential'
import GeneralAccessibility from './routes/GeneralAccessibility'
import Location from './routes/Location'
import Photos from './routes/Photos'

const Edit = (props) => {
  const { audit } = props

  return (
    <Switch>
      <Redirect exact from="/audits/:audit_id/" to="audits/:audit_id/general" />
      <Redirect
        exact
        from="audits/:audit_id/edit"
        to="audits/:audit_id/edit/general"
      />

      <PrivateRoute
        path="/audits/:audit_id/edit/general"
        component={General}
        redirectTo={`/audits/${audit.id}`}
        authorized={
          !(
            audit.forbidden_features &&
            audit.forbidden_features.includes('general')
          )
        }
        pendingAuthorization={false}
      />
      <PrivateRoute
        path="/audits/:audit_id/edit/referential"
        component={Referential}
        redirectTo={`/audits/${audit.id}`}
        authorized={
          !(
            audit.forbidden_features &&
            audit.forbidden_features.includes('referential')
          )
        }
        pendingAuthorization={false}
      />
      <PrivateRoute
        path="/audits/:audit_id/edit/general-accessibility"
        component={GeneralAccessibility}
        redirectTo={`/audits/${audit.id}`}
        authorized={
          !(
            audit.forbidden_features &&
            audit.forbidden_features.includes('accessibility')
          )
        }
        pendingAuthorization={false}
      />
      <PrivateRoute
        path="/audits/:audit_id/edit/location"
        component={Location}
        redirectTo={`/audits/${audit.id}`}
        authorized={
          !(
            audit.forbidden_features &&
            audit.forbidden_features.includes('location')
          )
        }
        pendingAuthorization={false}
      />
      <PrivateRoute
        path="/audits/:audit_id/edit/photos"
        component={Photos}
        redirectTo={`/audits/${audit.id}`}
        authorized={
          !(
            audit.forbidden_features &&
            audit.forbidden_features.includes('photos')
          )
        }
        pendingAuthorization={false}
      />
    </Switch>
  )
}

const mapStateToProps = (state) => ({
  audit: state.control.audit,
})

export default connect(mapStateToProps, null)(Edit)
