import { push } from 'connected-react-router'
import cache from './CacheHandler'

const handleGetMethod = async (request, callbacks, dispatch) => {
  const data = await cache.get(`initialState/${request.suffix}`)

  if (data) {
    callbacks.success(data)
  } else {
    dispatch(push('/offline_error'))
  }
}

const resolveRequestToSave = async (request, attributes) => {
  const pendingRequestToMerge = await cache.get(
    `pendingRequest/${request.suffix}`,
  )
  const failedRequestToMerge = await cache.get(
    `failedRequest/${request.suffix}`,
  )

  let finalRequestToMerge = null

  if (failedRequestToMerge !== undefined) {
    finalRequestToMerge = failedRequestToMerge
    await cache.delete(`failedRequest/${request.suffix}`)
  } else {
    finalRequestToMerge = pendingRequestToMerge
  }

  return request.requestMerger(finalRequestToMerge, JSON.parse(attributes.body))
}

const handlePatchMethod = async (request, attributes, callbacks, dispatch) => {
  const state = await cache.get(`initialState/${request.suffix}`)

  if (!state) {
    dispatch(push('/offline_error'))
  }

  const newState = await request.stateHydrater(
    state,
    JSON.parse(attributes.body),
  )

  await cache.set(`initialState/${request.suffix}`, newState)
  const requestToSave = await resolveRequestToSave(request, attributes)
  await cache.set(`pendingRequest/${request.suffix}`, requestToSave)
  callbacks.success(newState)
}

const offlineResolverWrapper = (request, attributes, callbacks, dispatch) => {
  if (attributes.method === 'GET') {
    handleGetMethod(request, callbacks, dispatch)
  }

  if (attributes.method === 'PATCH') {
    handlePatchMethod(request, attributes, callbacks, dispatch)
  }
}

export default offlineResolverWrapper
