/* eslint-disable camelcase */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { replace } from 'lodash'

const useStyles = makeStyles((theme) => ({
  selected: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '0.9rem',
  },
  rootItemTextSize: {
    fontSize: '0.9rem',
    color: theme.palette.text.primary,
  },
}))

const formatUrl = (url, audit_id) => {
  let result = url
  if (audit_id) {
    result = replace(result, ':audit_id', audit_id)
  }
  return result
}

const MenuItem = (props) => {
  const {
    item,
    key,
    audit,
    handleDrawerToggle,
    history,
    location: { pathname },
  } = props

  const classes = useStyles()

  const isSelected = () => pathname === formatUrl(item.url, audit.id)

  return (
    <ListItem
      key={key}
      button
      onClick={() => {
        if (handleDrawerToggle) handleDrawerToggle()
        history.push(formatUrl(item.url, audit.id))
      }}
      disabled={item.disabled}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText
        primary={item.label}
        primaryTypographyProps={
          (isSelected() && {
            className: classes.selected,
          }) || { className: classes.rootItemTextSize }
        }
      />
    </ListItem>
  )
}

export default withRouter(MenuItem)
