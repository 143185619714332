import { SET_ANCHOR_EL, SET_TYPE, SET_PROGRESS } from '../constants'

const initialState = {
  anchorEl: null,
  type: 'idle',
  progress: 0,
}

const cacheDownloadManager = (state = initialState, action = { type: '' }) => {
  switch (action.type) {
    case SET_ANCHOR_EL:
      return {
        ...state,
        anchorEl: action.payload.anchorEl,
      }
    case SET_TYPE:
      return {
        ...state,
        type: action.payload.type,
      }
    case SET_PROGRESS:
      return {
        ...state,
        progress: action.payload.progress,
      }
    default:
      return state
  }
}

export default cacheDownloadManager
