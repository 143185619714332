/* eslint-disable camelcase */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Navbar from 'components/Navbar'
import AuditSubmit from 'components/AuditSubmit'

const Submit = (props) => {
  const { audit } = props
  const { t } = useTranslation('routes')

  return (
    <>
      <Navbar name={t('routes:audit.submit.name')} />
      <AuditSubmit audit={audit} />
    </>
  )
}

const mapStateToProps = (state) => ({
  audit: state.control.audit,
})

export default connect(mapStateToProps, null)(Submit)
