import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Tooltip, makeStyles } from '@material-ui/core'
import { Sync } from '@material-ui/icons'

const useStyles = makeStyles(() => ({
  white: {
    color: 'white',
  },
}))

const AuditsWithNoPendingRequest = () => {
  const classes = useStyles()
  const { t } = useTranslation('atoms')

  return (
    <Tooltip
      title={t(
        'atoms:AppMenu.AuditSyncManager.AuditsWithNoPendingRequest.tooltip',
      )}
    >
      <IconButton>
        <Sync className={classes.white} />
      </IconButton>
    </Tooltip>
  )
}

export default AuditsWithNoPendingRequest
