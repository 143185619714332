/* eslint-disable no-console */
// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.
import React from 'react'
import { createRoot } from 'react-dom'
import UpdateNotification from 'components/UpdateNotification'

// const isLocalhost = Boolean(
//   window.location.hostname === 'localhost' ||
//     // [::1] is the IPv6 localhost address.
//     window.location.hostname === '[::1]' ||
//     // 127.0.0.1/8 is considered localhost for IPv4.
//     window.location.hostname.match(
//       /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
//     ) ||
//     window.location.hostname === 'audit.pictoaccess.test',
// )

function promptUserToRefresh(registration) {
  const registrationSkipWaiting = () => {
    try {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      registration.waiting.postMessage('SKIP_WAITING')
    } catch (e) {
      console.warn('registrationSkipWaiting: ', e)
      window.location.reload()
    }
  }

  const container = document.getElementById('service-worker')
  const root = createRoot(container)

  root.render(
    <UpdateNotification registrationSkipWaiting={registrationSkipWaiting} />,
  )
}

function listenForWaitingServiceWorker(registration, callback) {
  const awaitStateChange = () => {
    registration.installing.addEventListener('statechange', (e) => {
      if (e.target.state === 'installed') {
        callback(registration)
      }
    })
  }

  if (!registration) return
  if (registration.waiting) {
    callback(registration)
    return
  }
  if (registration.installing) awaitStateChange()
  registration.addEventListener('updatefound', awaitStateChange)
}

const registerValidSW = async (swUrl) => {
  let refreshing = false
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (refreshing) return
    refreshing = true
    window.location.reload()
  })

  try {
    console.log('navigator', navigator)
    const registration = await navigator.serviceWorker.register(swUrl)
    console.log('registration', registration)

    listenForWaitingServiceWorker(registration, promptUserToRefresh)
  } catch (error) {
    console.error(
      'registerValidSW: Error during service worker registration:',
      error,
    )
  }
}

function checkValidServiceWorker(swUrl) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then((response) => {
      if (
        response.status === 404 ||
        response.headers.get('content-type').indexOf('javascript') === -1
      ) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        registerValidSW(swUrl)
      }
    })
    .catch((err) => {
      console.error('checkValidServiceWorker', err)
    })
}

export function register() {
  if ('serviceWorker' in navigator) {
    // if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location)
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
      return
    }

    const load = () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

      checkValidServiceWorker(swUrl)
    }

    window.addEventListener('load', load)
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister()
    })
  }
}
