import { combineReducers } from 'redux'
import pictures from './pictures'
import control from './control'

const pictureGallery = combineReducers({
  pictures,
  control,
})

export default pictureGallery
