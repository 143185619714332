/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react'
import cache from 'api/utils/CacheHandler'
import { map } from 'lodash'
import AuditsNoPendingRequest from './AuditsWithNoPendingRequest'
import AuditsWithPendingRequests from './AuditsWithPendingRequests'
import AuditsWithFailedRequests from './AuditsWithFailedRequests'

const AuditSyncManager = () => {
  const [state, setState] = useState({
    pendingRequestsCount: 0,
    failedRequestsCount: 0,
    failedAuditIds: [],
  })

  const updatePendingRequestsCounter = () => {
    cache.countPendingRequests().then((result) => {
      setState((prevState) => ({
        ...prevState,
        pendingRequestsCount: result,
      }))
    })
  }

  const updatefailedRequestsCounter = () => {
    cache.getFailedRequests().then((failedRequests) => {
      const failedRequestsCount = failedRequests.length
      const failedAuditIds = map(
        failedRequests,
        (failedRequestObj) => failedRequestObj.key.split('/')[2],
      )

      setState((prevState) => ({
        ...prevState,
        failedRequestsCount,
        failedAuditIds,
      }))
    })
  }

  useEffect(() => {
    updatePendingRequestsCounter()
    updatefailedRequestsCounter()

    window.addEventListener('DataStorageChanged', updatePendingRequestsCounter)
    window.addEventListener('DataStorageChanged', updatefailedRequestsCounter)

    return () => {
      window.removeEventListener(
        'DataStorageChanged',
        updatePendingRequestsCounter,
      )
      window.removeEventListener(
        'DataStorageChanged',
        updatefailedRequestsCounter,
      )
    }
  }, [])

  const { pendingRequestsCount, failedAuditIds, failedRequestsCount } = state

  let toRender = null

  if (pendingRequestsCount === 0 && failedRequestsCount === 0) {
    toRender = <AuditsNoPendingRequest />
  } else if (pendingRequestsCount > 0) {
    toRender = (
      <AuditsWithPendingRequests pendingRequestsCount={pendingRequestsCount} />
    )
  } else if (failedRequestsCount > 0) {
    toRender = (
      <AuditsWithFailedRequests
        failedAuditIds={failedAuditIds}
        failedRequestsCount={failedRequestsCount}
      />
    )
  }

  return toRender
}

export default AuditSyncManager
