import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import All from './routes/All'
import Audit from './routes/Audit'

// eslint-disable-next-line no-unused-vars
const Audits = ({ match: { url } }) => (
  <Switch>
    <Redirect exact from="/audits/" to="/audits/all" />
    <Route path="/audits/all" component={All} />
    <Route path="/audits/:audit_id" component={Audit} />
  </Switch>
)

export default Audits
