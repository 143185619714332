import {
  SET_PICTURE_GALLERIES,
  SET_LOADING,
  SET_COVER_PICTURE,
} from '../constants'

const initalState = {
  loading: true,
  pictureGalleries: [],
  coverPicture: null,
}

const pictureGalleries = (state = initalState, action = { type: '' }) => {
  switch (action.type) {
    case SET_PICTURE_GALLERIES:
      return {
        ...state,
        pictureGalleries: action.pictureGalleries,
      }
    case SET_COVER_PICTURE:
      return {
        ...state,
        coverPicture: action.coverPicture,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    default:
      return state
  }
}

export default pictureGalleries
