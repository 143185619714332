import React from 'react'
import { CardEdit } from 'services'
import Navbar from 'components/Navbar'
import { useTranslation } from 'react-i18next'

const General = () => {
  const { t } = useTranslation('routes')

  return (
    <>
      <Navbar name={t('routes:audit.edit.general.name')} />
      <CardEdit />
    </>
  )
}

export default General
