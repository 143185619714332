import localforage from 'localforage'

const storage = {
  currentInstance: null,
}

const allStorages = []

export const createMainStorage = (missionToken) => {
  storage.currentInstance = localforage.createInstance({
    driver: [
      localforage.INDEXEDDB,
      localforage.WEBSQL,
      localforage.LOCALSTORAGE,
    ],
    name: `PictoAuditDashboard-${missionToken}`,
  })
}

export const createStorage = (name) =>
  localforage.createInstance({
    driver: [
      localforage.INDEXEDDB,
      localforage.WEBSQL,
      localforage.LOCALSTORAGE,
    ],
    name,
  })

export const getStorage = (name) => {
  if (allStorages[name] === undefined) {
    const newStorage = createStorage(name)
    allStorages[name] = newStorage
  }

  return allStorages[name]
}

export const getAllStorages = () => allStorages

export const getMainStorage = () => storage.currentInstance
