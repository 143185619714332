import {
  SET_THEME,
  SET_AUDIT,
  SET_AUDIT_MISSION,
  POP_SNACKBAR,
  SET_SNACKBAR,
} from 'constants/ActionTypes'
import { push } from 'connected-react-router'
import { getMainStorage } from 'store/forage'

export const setTheme = (theme) => ({
  type: SET_THEME,
  payload: theme,
})

export const setAudit = (audit) => ({
  type: SET_AUDIT,
  payload: audit,
})

export const setAuditMission = (auditMission) => ({
  type: SET_AUDIT_MISSION,
  payload: auditMission,
})

export const popSnackbar = (message, snackType) => ({
  type: POP_SNACKBAR,
  message,
  snack_type: snackType,
})

export const setSnackbar = (attribute, value) => ({
  type: SET_SNACKBAR,
  attribute,
  value,
})

const setLogout = () => ({
  type: 'control/LOGOUT',
})

export const logOut =
  (redirect = true) =>
  (dispatch) => {
    localStorage.clear()
    getMainStorage()
      .clear()
      .then(() => {
        if (redirect === true) {
          dispatch(push('/'))
        }
        dispatch(setLogout())
        window.location.reload()
      })
      .catch(() => {
        dispatch(setLogout())
        window.location.reload()
      })
  }
