import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Button, Box, makeStyles } from '@material-ui/core'
import { map, isEmpty, compact } from 'lodash'
import { isDatabasesSupported } from 'utils/indexedDB'
import { useStorageInit } from 'contexts/storage'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  auditMissionsTitle: {
    marginTop: theme.spacing(4),
  },
  auditMissionLinkListItemContainer: {
    marginTop: theme.spacing(2),
  },
  auditMissionLinkListItem: {
    width: '100%',
  },
}))

const AuditMissionInCacheShortcuts = ({ setToken }) => {
  const { t } = useTranslation('services')
  const classes = useStyles()
  const [auditMissionsInCache, setAuditMissionsInCache] = useState([])
  const { storages } = useStorageInit()

  useEffect(() => {
    if (!isDatabasesSupported()) return

    if (!isEmpty(Object.keys(storages))) {
      const promises = map(Object.keys(storages), (storageKey) =>
        storages[storageKey].getItem('initialState/audit_missions'),
      )

      Promise.all(promises).then((results) => {
        const auditMissionTitlesObject = compact(
          map(results, (item) => {
            if (item === null) return null

            return {
              title: `[${item.data.ref}] ${item.data.title}`,
              token: item.data.token,
            }
          }),
        )

        setAuditMissionsInCache(auditMissionTitlesObject)
      })
    }
  }, [storages])

  if (isEmpty(auditMissionsInCache)) return null

  return (
    <Box className={classes.root}>
      <Typography
        variant="h6"
        component="h3"
        className={classes.auditMissionsTitle}
      >
        {t('services:SignInForm.AuditMissionInCacheShortcuts.header')}
      </Typography>
      {map(auditMissionsInCache, (item) => (
        <Box
          className={classes.auditMissionLinkListItemContainer}
          key={item.token}
        >
          <Button
            className={classes.auditMissionLinkListItem}
            key={item.token}
            variant="contained"
            color="secondary"
            onClick={(e) => {
              setToken(item.token)
              e.preventDefault()
            }}
            href={`/sign_in?token=${item.token}`}
          >
            <Typography component="span" variant="body1">
              {item.title}
            </Typography>
          </Button>
        </Box>
      ))}
    </Box>
  )
}

export default AuditMissionInCacheShortcuts
