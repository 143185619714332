/* eslint-disable camelcase */
import { filter, forEach, get } from 'lodash'
import cache from './utils/CacheHandler'

const hydrater = async (state, body) => {
  const nextState = JSON.parse(JSON.stringify(state))
  const card_attributes = get(body, 'audit.card_attributes', {})

  // categories
  if (card_attributes.category_ids) {
    const cached_categories = await cache.get(`initialState/cache/categories`)
    nextState.audit.categories = filter(cached_categories.categories, (cat) =>
      card_attributes.category_ids.includes(cat.id),
    )
  }

  // tags
  if (card_attributes.tag_ids) {
    const cached_tags = cache.get(`initialState/cache/tags`)
    nextState.audit.tags = filter(cached_tags.tags, (tag) =>
      card_attributes.tag_ids.includes(tag.id),
    )
  }

  // contact
  if (card_attributes.contact_attributes) {
    const contact_attributes = get(card_attributes, 'contact_attributes', {})

    forEach(contact_attributes, (value, key) => {
      if (typeof value !== 'object') {
        nextState.audit.contact[key] = contact_attributes[key]
      }
    })
  }

  // content
  if (card_attributes.content_attributes) {
    const content_attributes = get(card_attributes, 'content_attributes', {})

    forEach(content_attributes, (value, key) => {
      nextState.audit.content[key] = content_attributes[key]
    })
  }

  // accessibility_description
  if (card_attributes.accessibility_description_attributes) {
    const accessibility_description_attributes = get(
      card_attributes,
      'accessibility_description_attributes',
      {},
    )

    if (accessibility_description_attributes.handicaps) {
      nextState.audit.accessibility_description.handicaps =
        accessibility_description_attributes.handicaps
    }

    if (accessibility_description_attributes.referential_attributes) {
      nextState.audit.accessibility_description.referential = {
        ...nextState.audit.accessibility_description.referential,
        ...accessibility_description_attributes.referential_attributes,
      }
    }
  }

  return nextState
}

const merger = (prev_request, new_request) => {
  if (!prev_request) {
    return new_request
  }

  const res = {
    audit: {
      card_attributes: {
        ...prev_request.audit.card_attributes,
        ...new_request.audit.card_attributes,
      },
    },
  }

  if (new_request.audit.card_attributes.accessibility_description_attributes) {
    res.audit.card_attributes.accessibility_description_attributes = {
      ...prev_request.audit.card_attributes
        .accessibility_description_attributes,
      ...new_request.audit.card_attributes.accessibility_description_attributes,
    }
  }
  return res
}

export const getAudits = () => ({
  suffix: 'audits',
  cachable: true,
})

export const getAudit = (id) => ({
  suffix: `audits/${id}`,
  cachable: true,
})

export const updateAudit = (id) => ({
  suffix: `audits/${id}`,
  method: 'PATCH',
  cachable: true,
  stateHydrater: hydrater,
  requestMerger: merger,
})

export const submitAudit = (id) => ({
  suffix: `audits/${id}/submit`,
  method: 'PATCH',
})
