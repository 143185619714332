import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Typography,
  Badge,
} from '@material-ui/core'
import { SyncDisabled } from '@material-ui/icons'
import { popSnackbar as popSnackbarAction } from 'actions/Control'
import { pendingRequestHandler } from 'api/utils/pendingRequestHandler'
import { useApiContext } from 'contexts/api'
import { makeStyles, createStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    white: {
      color: 'white',
    },
    badge: {
      right: -15,
      width: 17,
      height: 17,
      fontSize: '0.65rem',
      border: `2px solid ${
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[900]
      }`,
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[900],
    },
  }),
)

const AuditsWithPendingRequests = (props) => {
  const { pendingRequestsCount, popSnackbar } = props
  const { apiCall } = useApiContext()
  const classes = useStyles()
  const { t } = useTranslation('atoms')

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClickEmptyPendingRequest = () => {
    pendingRequestHandler(apiCall, popSnackbar)
    handleClose()
  }

  return (
    <>
      <Tooltip
        title={t(
          'atoms:AppMenu.AuditSyncManager.AuditsWithPendingRequests.tooltip',
        )}
      >
        <IconButton onClick={navigator.onLine ? (e) => handleClick(e) : null}>
          <Badge
            badgeContent={pendingRequestsCount}
            classes={{ badge: classes.badge }}
          >
            <SyncDisabled className={classes.white} />
          </Badge>
        </IconButton>
      </Tooltip>
      {navigator.onLine ? (
        <Menu
          id="sync-pending-request-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem key={3} onClick={handleClickEmptyPendingRequest}>
            <Typography variant="inherit" noWrap>
              {t(
                'atoms:AppMenu.AuditSyncManager.AuditsWithPendingRequests.forceDataSync',
              )}
            </Typography>
          </MenuItem>
        </Menu>
      ) : null}
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  popSnackbar: (msg, type) => dispatch(popSnackbarAction(msg, type)),
})

export default connect(null, mapDispatchToProps)(AuditsWithPendingRequests)
