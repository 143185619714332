import {
  SET_BUSY,
  SET_MODAL_OPEN,
  SET_MODAL_PICTURE,
  SET_PICTURE,
} from '../constants'

const initalState = {
  busy: false,
  modalOpen: false,
  picture: null,
  errors: [],
  card: null,
}

const CoverPictureEditor = (state = initalState, action = { type: '' }) => {
  switch (action.type) {
    case SET_BUSY:
      return {
        ...state,
        busy: action.value,
      }
    case SET_PICTURE: {
      return {
        ...state,
        picture: action.value,
      }
    }
    case SET_MODAL_OPEN:
      return {
        ...state,
        modalOpen: action.payload,
      }
    case SET_MODAL_PICTURE:
      return {
        ...state,
        card: action.payload,
      }
    default:
      return state
  }
}

export default CoverPictureEditor
