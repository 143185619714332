/* eslint-disable camelcase */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { map } from 'lodash'
import {
  Collapse,
  ListItemText,
  ListItemSecondaryAction,
  List,
  ListItem,
  ListItemIcon,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import Build from '@material-ui/icons/Build'
import ExpandMore from '@material-ui/icons/ExpandMore'

const styles = (theme) => ({
  nested: {
    paddingLeft: theme.spacing(2),
  },
  selected: { fontWeight: 'bold', color: theme.palette.primary.main },
  rootItemTextSize: { fontSize: '0.9rem' },
})

const formatUrl = (url, audit_id) => {
  let result = url
  if (audit_id !== null) {
    result = url.replace(':audit_id', audit_id)
  }

  return result
}

const CollapsingMenuItem = ({
  classes,
  item,
  audit,
  audit_id,
  history,
  location,
  handleDrawerToggle,
}) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => setOpen(!open)
  const isSelected = (itemSelected) =>
    location.pathname === formatUrl(itemSelected.url, audit_id)

  const generateListItem = (sub_item, i) => {
    if (
      audit.forbidden_features &&
      audit.forbidden_features.includes(sub_item.slug)
    ) {
      return null
    }

    return (
      <ListItem
        key={i}
        button
        className={classes.nested}
        onClick={() => {
          if (handleDrawerToggle) handleDrawerToggle()
          history.push(formatUrl(sub_item.url, audit_id))
        }}
        disabled={sub_item.disabled}
      >
        <ListItemText
          secondary={sub_item.label}
          secondaryTypographyProps={
            (isSelected(sub_item) && {
              className: classes.selected,
            }) ||
            null
          }
        />
        {sub_item.disabled && (
          <ListItemSecondaryAction>
            <Build color="disabled" />
          </ListItemSecondaryAction>
        )}
      </ListItem>
    )
  }

  return [
    <ListItem key={0} button onClick={handleClick}>
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText
        primary={item.label}
        primaryTypographyProps={{
          color: 'textPrimary',
          className: classes.rootItemTextSize,
        }}
      />
      {open ? (
        <ExpandLess color="secondary" />
      ) : (
        <ExpandMore color="secondary" />
      )}
    </ListItem>,
    <Collapse key={1} in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {map(item.sub_items, (sub_item, i) => generateListItem(sub_item, i))}
      </List>
    </Collapse>,
  ]
}

const mapStateToProps = (state) => ({
  audit: state.control.audit,
})

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps, null)(CollapsingMenuItem),
  ),
)
