import { combineReducers } from 'redux'
import cardEdit from 'services/CardEdit/reducers'
import auditList from 'services/AuditList/reducers'
import auditMissionDashboard from 'services/AuditMissionDashboard/reducers'
import referential from 'services/AccessibilityReferentialForm/reducers'
import LocationForm from 'services/LocationForm/reducers'
import CoverPictureEditor from 'services/CoverPictureEditor/reducers'
import pictureGallery from 'services/PictureGallery/reducers'
import pictureGalleries from 'services/PictureGalleries/reducers'
import cacheDownloadManager from 'services/CacheDownloadManager/reducers'

const services = combineReducers({
  auditList,
  auditMissionDashboard,
  cardEdit,
  referential,
  LocationForm,
  CoverPictureEditor,
  pictureGallery,
  pictureGalleries,
  cacheDownloadManager,
})

const rootServicesReducer = (state, action) => {
  if (action.type === 'control/LOGOUT') {
    // eslint-disable-next-line no-param-reassign
    state = undefined
  }
  return services(state, action)
}

export default rootServicesReducer
