/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Divider, List } from '@material-ui/core'
import { get, map, forEach } from 'lodash'
import CollapsingMenuItem from './CollapsingMenuItem'
import MenuItem from './MenuItem'

const promiseAll = (hash) => {
  const keys = Object.keys(hash)
  const values = map(keys, (key) => hash[key])
  return Promise.all(values).then((resolved_values) => {
    const resolved_hash = {}
    keys.forEach((key, index) => {
      resolved_hash[key] = resolved_values[index]
    })
    return resolved_hash
  })
}

const SidebarMenu = (props) => {
  const { items, audit, handleDrawerToggle } = props
  const [loading, setLoading] = useState(true)
  const [itemResolved, setItemResolved] = useState({})

  const generateSeparator = (i) => <Divider key={i} />
  const generateSubItems = (item, i) => (
    <CollapsingMenuItem
      key={i}
      item={item}
      audit_id={audit.id}
      handleDrawerToggle={handleDrawerToggle}
    />
  )

  useEffect(() => {
    const resolvePredicates = (itms) => {
      const promises = {}

      forEach(itms, (item) => {
        if (item.predicate !== undefined && item.async) {
          if (item.subject === 'Audit') {
            promises[item.predicate_id] = item.predicate(audit)
          }
        }
      })

      promiseAll(promises)
        .then((res) => {
          setItemResolved(res)
          setLoading(false)
        })
        .catch(() => setLoading(false))
    }
    resolvePredicates(items)

    return () => {
      setItemResolved({})
      setLoading(true)
    }
  }, [audit, items])

  if (loading) return null

  return (
    <List>
      {map(items, (item, i) => {
        let ret = null
        if (item.separator) {
          ret = generateSeparator(i)
        } else if (get(item, 'sub_items.length')) {
          ret = generateSubItems(item, i)
        } else if (item.predicate !== undefined) {
          if (item.async && itemResolved[item.predicate_id] === true) {
            ret = <MenuItem key={i} audit={audit} item={item} />
          } else if (!item.async && item.predicate(audit)) {
            ret = <MenuItem key={i} audit={audit} item={item} />
          }
        } else {
          ret = <MenuItem key={i} audit={audit} item={item} />
        }
        return ret
      })}
    </List>
  )
}

const mapStateToProps = (state) => ({
  audit: state.control.audit,
})
export default connect(mapStateToProps, null)(SidebarMenu)
