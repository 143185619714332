/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import OfflineError from 'components/OfflineError'

const onlyOnline = (WrappedComponent) => {
  const Wrapper = (props) => {
    const [online, setOnline] = useState(window.navigator.onLine)

    const updateOnlineIndicator = () => setOnline(window.navigator.onLine)

    useEffect(() => {
      window.addEventListener('online', updateOnlineIndicator)
      window.addEventListener('offline', updateOnlineIndicator)

      return () => {
        window.removeEventListener('online', updateOnlineIndicator)
        window.removeEventListener('offline', updateOnlineIndicator)
      }
    })

    return online ? <WrappedComponent {...props} /> : <OfflineError />
  }

  return Wrapper
}

export default onlyOnline
