import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { push as pushAction } from 'connected-react-router'
import {
  setSnackbar as setSnackbarAction,
  popSnackbar as popSnackbarAction,
} from 'actions/Control'
import { withAuditMissionAuthorization } from 'wrappers'
import PictoLoader from 'components/PictoLoader'
import PageForLoggedAndAuthorizedUser from 'app/PageForLoggedAndAuthorizedUser'
import PageNotLoggedUser from 'app/PageNotLoggedUser'
import { useAuthContext } from 'contexts/auth'
import { useTranslation } from 'react-i18next'
// import { useApiContext } from 'contexts/api'
// import { attachPendingRequestHandler } from 'api/utils/pendingRequestHandler'

const App = (props) => {
  const {
    location: { pathname },
    snackbar,
    authorized,
    pendingAuthorization,
    setSnackbar,
    popSnackbar,
    push,
    loadingAuditMission,
  } = props

  const { t } = useTranslation('routes')

  // const { apiCall } = useApiContext()
  const { logPending, notLogged, isLogged, logOut } = useAuthContext()

  useEffect(() => {
    // if (isLogged) attachPendingRequestHandler(apiCall, popSnackbar)
  }, [logPending])

  const loggedAndUnauthorized =
    isLogged && pendingAuthorization === false && authorized === false
  const loggedAndAuthorized =
    isLogged && pendingAuthorization === false && authorized === true

  if (logPending) {
    return null
  }

  if (notLogged) {
    if (pathname === '/sign_in') {
      return (
        <>
          {loadingAuditMission && <PictoLoader />}
          <PageNotLoggedUser snackbar={snackbar} setSnackbar={setSnackbar} />
        </>
      )
    }
    push('/sign_in')
    return null
  }

  if (loggedAndAuthorized) {
    if (pathname === '/sign_in') {
      popSnackbar(t('routes:app.already_connected'))
      push('/')
      return null
    }
    return (
      <>
        {loadingAuditMission && <PictoLoader />}
        <PageForLoggedAndAuthorizedUser
          snackbar={snackbar}
          setSnackbar={setSnackbar}
        />
      </>
    )
  }

  if (loggedAndUnauthorized) logOut()

  return null
}

const mapStateToProps = (state) => ({
  snackbar: state.control.snackbar,
})

const mapDispatchToProps = (dispatch) => ({
  setSnackbar: (theme) => dispatch(setSnackbarAction(theme)),
  popSnackbar: (msg, type = 'warning') =>
    dispatch(popSnackbarAction(msg, type)),
  push: (path) => dispatch(pushAction(path)),
})

export default withAuditMissionAuthorization(
  connect(mapStateToProps, mapDispatchToProps)(App),
)
