import React, { useState } from 'react'
import { connect } from 'react-redux'
import { push as pushAction } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import {
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Typography,
  Badge,
} from '@material-ui/core'
import { map } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import { SyncProblem } from '@material-ui/icons'
import cache from 'api/utils/CacheHandler'
import { red } from '@material-ui/core/colors'

const useStyles = makeStyles(() => ({
  white: {
    color: 'white',
  },
  failedBadge: {
    right: -15,
    width: 17,
    height: 17,
    fontSize: '0.65rem',
    color: 'white',
    border: `2px solid ${red[400]}`,
    backgroundColor: red[500],
  },
}))

const AuditsWithFailedRequests = (props) => {
  const { failedAuditIds, failedRequestsCount, push } = props
  const { t } = useTranslation('atoms')

  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickFailedAudit = (auditId) => {
    push(`/audits/${auditId}`)
    handleClose()
  }

  const handleClickResolveFailedAudit = () => {
    cache.clearFailedRequests().then(() => handleClose())
  }

  return (
    <>
      <Tooltip
        title={t(
          'atoms:AppMenu.AuditSyncManager.AuditsWithFailedRequests.tooltip',
        )}
      >
        <IconButton onClick={(e) => handleClick(e)}>
          <Badge
            badgeContent={failedRequestsCount}
            classes={{ badge: classes.failedBadge }}
          >
            <SyncProblem className={classes.white} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id="sync-issue-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {map(failedAuditIds, (auditId) => (
          <MenuItem
            key={auditId}
            onClick={() => handleClickFailedAudit(auditId)}
          >
            <Typography variant="inherit" noWrap>
              {
                (t(
                  'atoms:AppMenu.AuditSyncManager.AuditsWithFailedRequests.errorOnAudit',
                ),
                { auditId })
              }
            </Typography>
          </MenuItem>
        ))}
        <MenuItem key={99999} onClick={handleClickResolveFailedAudit}>
          <Typography variant="inherit" noWrap>
            {t(
              'atoms:AppMenu.AuditSyncManager.AuditsWithFailedRequests.markProblemsAsResolved',
            )}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  push: (url) => dispatch(pushAction(url)),
})

export default connect(null, mapDispatchToProps)(AuditsWithFailedRequests)
