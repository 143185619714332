/* eslint-disable import/named */
import React from 'react'
import Navbar from 'components/Navbar'
import { StorageManager } from 'services'

const CachePage = () => (
  <>
    <Navbar name="Gestion de la mémoire locale" />
    <StorageManager />
  </>
)

export default CachePage
