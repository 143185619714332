import { get, isEmpty, forOwn, includes, find } from 'lodash'
import i18n from 'env/i18n'

const t = i18n.getFixedT(null, 'atoms')

export const SECTION_NAME = {
  accessibilityDescription: t(
    'atoms:AuditSubmit.sectionName.accessibilityDescription',
  ),
  content: t('atoms:AuditSubmit.sectionName.content'),
  contact: t('atoms:AuditSubmit.sectionName.contact'),
  localisation: t('atoms:AuditSubmit.sectionName.localisation'),
  tags: t('atoms:AuditSubmit.sectionName.tags'),
  categories: t('atoms:AuditSubmit.sectionName.categories'),
  referential: t('atoms:AuditSubmit.sectionName.referential'),
  pictureGalleries: t('atoms:AuditSubmit.sectionName.pictureGalleries'),
}

const hasHandicapNotSet = (handicaps) => {
  let ret = false
  forOwn(handicaps, (value) => {
    if (value === -2) {
      ret = true
    }
  })
  return ret
}

const hasReferential = (referential) => referential !== null
const hasAnswersInReferential = (referential) => !isEmpty(referential.answers)
const hasCitySet = (localisation) =>
  !isEmpty(localisation.city_name) &&
  localisation.city_name !== 'Ville par défaut'
const hasTitleSet = (content) =>
  includes(content.title, 'Titre contenu audit #')
const hasPhoneSet = (contact) => !isEmpty(contact.phone)
const hasMobilePhoneSet = (contact) => !isEmpty(contact.mobile_phone)
const hasEmailSet = (contact) => !isEmpty(contact.email)
const hasMissingPictureGalleries = (pictureGalleries) =>
  pictureGalleries.length !== 2

const hasLessThanThreePicturesInGallery = (pictureGalleries, type) => {
  const pictureGallery = find(
    pictureGalleries,
    (pG) => pG.picture_gallery_type === type,
  )

  if (isEmpty(pictureGallery)) return true

  return pictureGallery.pictures.length < 3
}

const hasPicture = (mainPicture) => !isEmpty(mainPicture)

export const generateAuditMissingFields = (audit) => {
  const results = {
    accessibilityDescription: [],
    content: [],
    contact: [],
    localisation: [],
    tags: [],
    categories: [],
    referential: [],
    pictureGalleries: [],
  }

  const auditObject = get(audit, 'audit', null)
  const categories = get(audit, 'audit.categories', null)
  const accessibilityDescription = get(
    audit,
    'audit.accessibility_description',
    null,
  )
  const referential = get(
    audit,
    'audit.accessibility_description.referential',
    null,
  )
  const content = get(audit, 'audit.content', null)
  const contact = get(audit, 'audit.contact', null)
  const localisation = get(audit, 'audit.localisation', null)
  const pictureGalleries = get(audit, 'audit.picture_galleries', null)
  const mainPicture = get(audit, 'audit.picture', null)

  if (auditObject !== null) {
    // Audit
    if (isEmpty(categories)) {
      results.categories.push(t('atoms:AuditSubmit.warnings.categories_empty'))
    }
    if (categories.length === 1) {
      results.categories.push(t('atoms:AuditSubmit.warnings.categories_length'))
    }

    // Content
    if (!hasTitleSet(content)) {
      results.content.push(t('atoms:AuditSubmit.warnings.content'))
    }

    // AccessibilityDescription
    if (hasHandicapNotSet(accessibilityDescription.handicaps)) {
      results.accessibilityDescription = [
        t('atoms:AuditSubmit.warnings.accessibilityDescription'),
      ]
    }

    // Referential
    if (!hasReferential(referential)) {
      results.referential.push(
        t('atoms:AuditSubmit.warnings.referential.not_set'),
      )
    } else if (!hasAnswersInReferential(referential)) {
      results.referential.push(
        t('atoms:AuditSubmit.warnings.referential.no_answers'),
      )
    }

    // Localisation
    if (!hasCitySet(localisation)) {
      results.localisation.push(
        t('atoms:AuditSubmit.warnings.localisation.city_not_set'),
      )
    }

    // Contact
    if (!hasPhoneSet(contact)) {
      results.contact.push(t('atoms:AuditSubmit.warnings.contact.noPhone'))
    }
    if (!hasMobilePhoneSet(contact)) {
      results.contact.push(
        t('atoms:AuditSubmit.warnings.contact.noMobilePhone'),
      )
    }
    if (!hasEmailSet(contact)) {
      results.contact.push(t('atoms:AuditSubmit.warnings.contact.email'))
    }

    // Picture
    if (!hasPicture(mainPicture)) {
      results.pictureGalleries.push(
        t('atoms:AuditSubmit.warnings.pictureGalleries.noMainPicture'),
      )
    }
    // PictureGalleries
    if (hasMissingPictureGalleries(pictureGalleries)) {
      results.pictureGalleries.push(
        t(
          'atoms:AuditSubmit.warnings.pictureGalleries.notEnoughPictureGalleries',
        ),
      )
    }
    if (hasLessThanThreePicturesInGallery(pictureGalleries, 1)) {
      results.pictureGalleries.push(
        t('atoms:AuditSubmit.warnings.pictureGalleries.notEnoughPagePictures'),
      )
    }
    if (hasLessThanThreePicturesInGallery(pictureGalleries, 2)) {
      results.pictureGalleries.push(
        t(
          'atoms:AuditSubmit.warnings.pictureGalleries.notEnoughAccessibilityPictures',
        ),
      )
    }
  }

  return results
}
