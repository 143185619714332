import React from 'react'
import { Box, CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    marginTop: theme.spacing(10),
  },
}))

const Loader = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <CircularProgress className={classes.progress} size={100} />
    </Box>
  )
}

export default Loader
