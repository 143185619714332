/* eslint-disable camelcase */
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import cache from 'api/utils/CacheHandler'

const MarkedAsResolved = ({
  match: {
    params: { audit_id },
  },
}) => {
  const [done, setDone] = useState(false)
  cache.failedRequestToPendingRequest(audit_id).then(() => setDone(true))

  return done ? (
    <Redirect
      exact
      from="/audits/:audit_id/marked_as_resolved"
      to={`/audits/${audit_id}/dashboard`}
    />
  ) : null
}

export default MarkedAsResolved
