/* eslint-disable import/named */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Hidden,
  Tooltip,
} from '@material-ui/core'
import { WifiOff, Wifi, PowerSettingsNew, Menu } from '@material-ui/icons'
import { makeStyles, createStyles, useTheme } from '@material-ui/styles'
import PictoAccessIcon from 'assets/img/picto-access-icon.png'
import { CacheDownloadManager } from 'services'
import { useAuthContext } from 'contexts/auth'
import SidebarMenu from 'components/SidebarMenu'
import Breadcrumb from 'components/Breadcrumb'
import AuditSyncManager from './AuditSyncManager'

const drawerWidth = 250

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      flexGrow: 1,
      zIndex: 1,
      // overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      width: '100%',
    },
    appBar: {
      // position: 'absolute',
    },
    navIconHide: {
      color: theme.palette.primary.contrastText,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    toolbar: {
      [theme.breakpoints.up('md')]: {
        height: 112,
      },
      height: 64,
      boxShadow: 'none',
    },
    drawerPaper: {
      top: 64,
      zIndex: 0,
      width: drawerWidth,
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        top: 112,
        minHeight: 'calc(100vh - 112px)',
      },
      height: 'calc(100% - 112px)',
    },
    drawerContainer: {
      backgroundColor: theme.palette.background.paper,
      height: '100%',
    },
    content: {
      minHeight: `calc(100vh - ${theme.spacing(6)}px)`,
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(3),
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(10),
      },
    },
    title: {
      color: theme.palette.primary.contrastText,
      flexGrow: 1,
    },
    staticIcon: {
      marginLeft: 10,
    },
    white: {
      color: 'white',
    },
  }),
)

const OnlineChecker = ({ classes, online }) => {
  const { t } = useTranslation('atoms')

  return online ? (
    <Tooltip title={t('atoms:AppMenu.OnlineChecker.online')}>
      <Wifi className={classes.staticIcon} />
    </Tooltip>
  ) : (
    <Tooltip title={t('atoms:AppMenu.OnlineChecker.offline')}>
      <WifiOff className={classes.staticIcon} />
    </Tooltip>
  )
}

const CacheDownloaderIcon = ({ online, isLogged }) =>
  isLogged && online ? <CacheDownloadManager /> : null

const LogoutButton = ({ logOut, isLogged }) => {
  const { t } = useTranslation('atoms')

  return isLogged ? (
    <Tooltip title={t('atoms:AppMenu.LogoutButton.logout')}>
      <IconButton color="inherit" onClick={() => logOut()}>
        <PowerSettingsNew />
      </IconButton>
    </Tooltip>
  ) : null
}

const DrawnerIcon = ({ classes, handleDrawerToggle, isLogged }) => {
  const { t } = useTranslation('atoms')

  return isLogged ? (
    <IconButton
      color="inherit"
      aria-label={t('atoms:AppMenu.DrawerIcon.openMenu')}
      onClick={handleDrawerToggle}
      className={classes.navIconHide}
    >
      <Menu />
    </IconButton>
  ) : null
}

const DisplayTitle = ({ classes }) => (
  <Typography className={classes.title} variant="h6" color="inherit" noWrap>
    <img src={PictoAccessIcon} alt="" height="20" style={{ marginRight: 5 }} />
    Picto Audit
  </Typography>
)

const AuditSyncIcon = ({ online, isLogged }) =>
  isLogged === true ? <AuditSyncManager online={online} /> : null

const AppMenu = (props) => {
  const { children, canBeOpened, items, audit_id } = props

  const classes = useStyles()
  const theme = useTheme()

  const { isLogged, logOut } = useAuthContext()

  const [mobileOpen, setMobileOpen] = useState(false)
  const [online, setOnline] = useState(true)

  useEffect(() => {
    const updateOnlineIndicator = () => setOnline(window.navigator.onLine)

    updateOnlineIndicator()

    window.addEventListener('online', updateOnlineIndicator)
    window.addEventListener('offline', updateOnlineIndicator)
    return () => {
      window.removeEventListener('online', updateOnlineIndicator)
      window.removeEventListener('offline', updateOnlineIndicator)
    }
  }, [])

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen)

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <DrawnerIcon
            classes={classes}
            handleDrawerToggle={handleDrawerToggle}
            isLogged={isLogged}
          />
          <DisplayTitle classes={classes} />
          <CacheDownloaderIcon online={online} isLogged={isLogged} />
          <AuditSyncIcon online={online} isLogged={isLogged} />
          <OnlineChecker online={online} classes={classes} />
          <LogoutButton logOut={logOut} isLogged={isLogged} />
        </Toolbar>
        <Breadcrumb />
      </AppBar>

      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          style={{
            display: canBeOpened ? 'block' : 'none',
          }}
          onClose={handleDrawerToggle}
          classes={{
            docked: classes.drawerContainer,
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <SidebarMenu
            handleDrawerToggle={handleDrawerToggle}
            items={items}
            audit_id={audit_id}
          />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          open={canBeOpened}
          style={{
            display: canBeOpened ? 'block' : 'none',
          }}
          classes={{
            docked: classes.drawerContainer,
            paper: classes.drawerPaper,
          }}
        >
          <SidebarMenu items={items} audit_id={audit_id} />
        </Drawer>
      </Hidden>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  )
}

export default AppMenu
