/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { popSnackbar } from 'actions/Control'
import { push } from 'connected-react-router'
import { getMainStorage } from 'store/forage'
import cache from './CacheHandler'

const isSuccessful = (status) => status >= 200 && status < 300

const isTokenExpired = (status) => status === 498

const isUnauthorized = (status) => status === 401

const resolverWrapper = (request, callbacks, dispatch) => {
  let finalCallbacks = callbacks

  const defaultFailure = (_) => {
    dispatch(popSnackbar('Une erreur est survenue', 'error'))
  }

  const defaultUnauthorized = (_) => {
    dispatch(push('/'))
    dispatch(
      popSnackbar("Vous n'êtes pas autorisé à consulter cette page", 'warning'),
    )
  }

  const tokenExpired = () => {
    localStorage.clear()
    const mainStorage = getMainStorage()
    const expiredRedirect = () => {
      dispatch(push('/'))
      dispatch(popSnackbar('Votre session a expiré', 'warning'))
    }
    if (mainStorage) {
      mainStorage
        .clear()
        .then(expiredRedirect)
        .catch((err) => console.log('tokenExpired', err))
    } else {
      expiredRedirect()
    }
  }
  if (finalCallbacks.failure === undefined) {
    finalCallbacks = { ...finalCallbacks, failure: defaultFailure }
  }

  if (finalCallbacks.unauthorized === undefined) {
    finalCallbacks = { ...finalCallbacks, unauthorized: defaultUnauthorized }
  }

  return ([status, data]) => {
    if (isSuccessful(status)) {
      if (request.cachable) {
        // pas besoin de promise
        cache.set(`initialState/${request.suffix}`, data)
      }
      finalCallbacks.success(data)
    } else if (isTokenExpired(status)) {
      tokenExpired()
    } else if (isUnauthorized(status)) {
      finalCallbacks.unauthorized(data)
    } else {
      finalCallbacks.failure(data)
    }
  }
}

export default resolverWrapper
