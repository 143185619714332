import { getAssetUrl } from 'api/utils'
import { get, map, orderBy } from 'lodash'

export const orderByIndex = (pictures) => orderBy(pictures, ['index'], ['asc'])

export const formatPictureUrl = (picture, type) => {
  if (picture.preview && picture.preview.startsWith('blob:')) {
    return picture.preview
  }

  let picturePath = get(picture, 'original_url', '')

  if (picture[`${type}_url`] !== undefined) {
    picturePath = picture[`${type}_url`]
  } else if (picture[`${type}`] !== undefined) {
    picturePath = picture[`${type}`]
  }

  if (!picturePath.startsWith('http')) {
    picturePath = getAssetUrl() + picturePath
  }

  return picturePath
}

export const updateObjectInArray = (pictures, picture, value, attribute) =>
  map(pictures, (item) => {
    if (item.id !== picture.id) {
      return item
    }
    return {
      ...item,
      [attribute]: value,
    }
  })

export const reindexedPictures = (pictures) =>
  map(orderByIndex(pictures), (item, index) => {
    const newItem = { ...item, index: index + 1 }
    return newItem
  })
