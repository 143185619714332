/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { createRoot } from 'react-dom/client'
import { ConnectedRouter } from 'connected-react-router'
import { Provider, connect } from 'react-redux'
import { createBrowserHistory } from 'history'
import {
  withRouter,
  Switch,
  Redirect,
  Route,
  matchPath,
} from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { AuthContext, useAuthInit } from 'contexts/auth'
import { setTheme, popSnackbar } from 'actions/Control'
import ApiProvider from 'contexts/api'
import configureStore from 'store/configureStore'
import App from 'app'
import 'styles/main.css'
import 'styles/fonts/fonts.css'
import { initSentry } from './sentry'
import * as serviceWorker from './registerServiceWorker'
import './env/i18n'

const routes = [
  {
    path: '/audits/:audit_id/dashboard',
  },
  {
    path: '/audits/:audit_id/edit',
  },
  {
    path: '/audits/:audit_id/edit/general',
  },
  {
    path: '/audits/:audit_id/edit/location',
  },
  {
    path: '/audits/:audit_id/edit/general-accessibility',
  },
  {
    path: '/audits/:audit_id/edit/referential',
  },
  {
    path: '/audits/:audit_id/edit/photos',
  },
  {
    path: '/audits/:audit_id/start',
  },
  {
    path: '/audits/:audit_id/submit',
  },
  {
    path: '/audits/:audit_id/marked_as_resolved',
  },
  {
    path: '/dashboard',
  },
  {
    path: '/audits',
  },
  {
    path: '/cache',
  },
]

const history = createBrowserHistory()

initSentry({
  router: {
    history,
    routes,
    matchPath,
  },
})

const store = configureStore(history)

const AppContainer = (props) => {
  const auth = useAuthInit()

  return (
    <ApiProvider dispatch={store.dispatch}>
      <AuthContext.Provider value={auth}>
        <MuiThemeProvider theme={props.theme}>
          <Switch>
            <Redirect exact from="/" to={`${props.match.url}dashboard`} />
            <Route
              path={`${props.match.url}`}
              render={() => <App {...props} />}
            />
          </Switch>
        </MuiThemeProvider>
      </AuthContext.Provider>
    </ApiProvider>
  )
}

const mapStateToProps = (state) => ({
  theme: state.control.theme,
})

const mapDispatchToProps = (dispatch) => ({
  setTheme: (theme) => dispatch(setTheme(theme)),
  popSnackbar: (msg, type) => dispatch(popSnackbar(msg, type)),
})

let AppContainerWrapped = AppContainer
AppContainerWrapped = withRouter(AppContainerWrapped)
AppContainerWrapped = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppContainerWrapped)

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AppContainerWrapped />
    </ConnectedRouter>
  </Provider>,
)

serviceWorker.register()
