import { get } from 'lodash'

export const getDisplayTitle = (audit) => {
  const title = get(audit, 'audit.content.title')
  const displayTitle = get(audit, 'audit.content.display_title')

  const ret = `${displayTitle || title}`

  return ret
}
