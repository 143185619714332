/* eslint-disable import/named */
import React from 'react'
import { connect } from 'react-redux'
import Navbar from 'components/Navbar'
import { AuditList } from 'services'
import { useTranslation } from 'react-i18next'

const All = ({ auditMission }) => {
  const { t } = useTranslation('routes')

  return (
    <>
      <Navbar
        name={t('routes:audits.all.name', {
          ref: auditMission.ref,
          title: auditMission.title,
        })}
      />
      <AuditList />
    </>
  )
}

const mapStateToProps = (state) => ({
  auditMission: state.control.auditMission,
})

export default connect(mapStateToProps, null)(All)
