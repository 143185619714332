/* eslint-disable no-console */
import { createContext, useContext, useState, useEffect } from 'react'
import { getStorage, getAllStorages } from 'store/forage'
import { forEach } from 'lodash'
import { captureEvent } from 'sentry'
import { isDatabasesSupported } from 'utils/indexedDB'

export const useStorageInit = () => {
  const [storages, setStorages] = useState({})
  const [dbReady, setDbReady] = useState(false)

  useEffect(() => {
    const initDbs = async () => {
      if (!isDatabasesSupported()) {
        setDbReady(true)
        return
      }

      try {
        const databasesName = await window.indexedDB.databases()
        forEach(databasesName, (item) => getStorage(item.name))
        setStorages(getAllStorages())
        setDbReady(true)
      } catch (err) {
        console.log(err)
        captureEvent(err)
      }
    }
    initDbs()
  }, [])

  return {
    storages,
    dbReady,
  }
}

export const StorageContext = createContext()

export function useStorageContext() {
  return useContext(StorageContext)
}
