import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { onlyOnline } from 'wrappers'
import Index from './routes/Index'
import Edit from './routes/Edit'
import Cover from './routes/Cover'

const Photos = () => (
  <Switch>
    <Redirect
      exact
      from="/audits/:audit_id/edit/photos/"
      to="/audits/:audit_id/edit/photos/list"
    />
    <Route path="/audits/:audit_id/edit/photos/list" component={Index} />
    <Route path="/audits/:audit_id/edit/photos/cover" component={Cover} />
    <Route
      path="/audits/:audit_id/edit/photos/:picture_gallery_slug/edit"
      component={Edit}
    />
  </Switch>
)

export default onlyOnline(Photos)
