import i18n from 'env/i18n'

const NOT_SUBMITED = 'not_submitted'
const IN_PROGRESS = 'in_progress'
const REJECTED = 'rejected'
const WAITING_FOR_REVIEW = 'waiting_for_review'
const ACCEPTED = 'accepted'
const PUBLISHED = 'published'

const t = i18n.getFixedT(null, 'atoms')

export const getAuditsSteps = (audit) => {
  return [
    {
      step: 0,
      text: t('atoms:AuditStatusStepper.notSubmitted'),
      isError: false,
    },
    {
      step: 1,
      text: t('atoms:AuditStatusStepper.inProgress'),
      isError: false,
    },
    {
      step: 2,
      text: t('atoms:AuditStatusStepper.waitingForReview'),
      isError: audit.status === REJECTED,
      hasAlertText: true,
      errorText: t('atoms:AuditStatusStepper.waitingForReviewError'),
      alertText:
        audit.status === REJECTED
          ? t('atoms:AuditStatusStepper.waitingForReviewAlertRejected')
          : t('atoms:AuditStatusStepper.waitingForReviewAlertInProgress'),
    },
    {
      step: 3,
      text: t('atoms:AuditStatusStepper.accepted'),
      isError: false,
    },
    {
      step: 5,
      text: t('atoms:AuditStatusStepper.published'),
      isError: false,
    },
  ]
}

export const getActiveStepFromAuditStatus = (audit) => {
  let ret = null

  switch (audit.status) {
    case NOT_SUBMITED:
      ret = 0
      break
    case IN_PROGRESS:
      ret = 1
      break
    case REJECTED:
    case WAITING_FOR_REVIEW:
      ret = 2
      break
    case ACCEPTED:
      ret = 3
      break
    case PUBLISHED:
      ret = 4
      break
    default:
      ret = 0
      break
  }

  return ret
}
