/* eslint-disable camelcase */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { onlyOnline } from 'wrappers'
import Navbar from 'components/Navbar'
import { LocationForm } from 'services'

const Location = ({
  match: {
    params: { audit_id },
  },
}) => {
  const { t } = useTranslation('routes')

  return (
    <>
      <Navbar name={t('routes:audit.edit.localisation.name')} />
      <LocationForm auditId={audit_id} />
    </>
  )
}

export default onlyOnline(Location)
