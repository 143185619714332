/* eslint-disable camelcase */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import { isEmpty, map } from 'lodash'
import WarningPanel from 'components/WarningPanel'
import { SECTION_NAME, generateAuditMissingFields } from './helpers'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(4),
    },
    listNone: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      marginTop: theme.spacing(2),
    },
  }),
)

const AuditMissingFieldsPanel = ({ audit }) => {
  const classes = useStyles()
  const { t } = useTranslation('atoms')

  const auditMissingFields = generateAuditMissingFields(audit)

  return (
    <WarningPanel className={classes.root}>
      <Typography variant="h5" component="span">
        {t('atoms:AuditSubmit.AuditMissingFieldsPanel.warningTitle')}
      </Typography>
      <Typography variant="body1" component="p">
        {t('atoms:AuditSubmit.AuditMissingFieldsPanel.warningSubtitle')}
      </Typography>
      <ul className={classes.listNone}>
        {map(auditMissingFields, (errors, key) => {
          if (isEmpty(errors)) return null
          return (
            <>
              <li>
                <Typography variant="h6" component="span">
                  {SECTION_NAME[key]}
                </Typography>
              </li>
              <ul>
                {map(errors, (error) => (
                  <li>
                    <Typography variant="body1" component="span">
                      {error}
                    </Typography>
                  </li>
                ))}
              </ul>
            </>
          )
        })}
      </ul>
    </WarningPanel>
  )
}

export default AuditMissingFieldsPanel
