/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { forEach } from 'lodash'
import * as AuditAPI from 'api/audit'
import * as ContactMessageAPI from 'api/contactMessage'
import cache from './CacheHandler'

export const synchronizePendingRequest = async (
  apiCall,
  storage,
  pendingRequestKey,
  popSnackBar,
  callback,
) => {
  const auditId = pendingRequestKey.split('/')[2]
  let item = null

  try {
    item = await storage.getItem(pendingRequestKey)
  } catch (err) {
    console.log('err', err)
    return
  }

  const success = () => async (data) => {
    try {
      await storage.removeItem(pendingRequestKey)
    } catch (err) {
      console.log('err', err)
    }
    popSnackBar('Audit synchronisé.', 'success')
    callback()
  }

  const failure = () => async (data) => {
    try {
      await storage.removeItem(pendingRequestKey)
      await storage.setItem(`failedRequest/audits/${auditId}`, item)
    } catch (err) {
      console.log('error pending request failure', err)
    }
    popSnackBar("Une erreur a empêché la synchronisation de l'audit", 'error')
    callback()
  }

  apiCall({
    request: AuditAPI.updateAudit(auditId),
    onSuccess: success(),
    onFailure: failure(),
    body: item.data,
  })
}

const notifyFailedSyncAudit = (apiCall, popSnackBar) => {
  const success = () => {
    popSnackBar(
      'Une notification à été envoyé a un administrateur Picto Access.',
      'warning',
    )
  }
  const failure = (err) => {
    console.log('notifyFailedSyncAudit', err)
  }

  const auditMission = JSON.parse(localStorage.getItem('auditMission') || {})

  cache.getFailedRequests().then((failedRequests) => {
    apiCall({
      request: ContactMessageAPI.createContactMessage(),
      onSuccess: success,
      onFailure: failure,
      body: {
        sender: `Audit Mission #${auditMission.id} [${auditMission.ref}]`,
        email: 'bug-audit@pictoaccess.fr',
        subject: 7,
        content: JSON.stringify(failedRequests),
        subject_object_id: auditMission.id,
        subject_object_type: 'AuditMission',
        user_type: 0,
      },
    })
  })
}

export const emptyPendingRequests = (apiCall, popSnackBar) => {
  if (!navigator.onLine) {
    return
  }
  cache.getPendingRequests().then((pendingRequests) => {
    let requestsSucceeded = 0
    let requestsFailed = 0
    const pendingRequestsCount = pendingRequests.length

    const complete = (requestsSucceededComplete, requestsFailedComplete) => {
      if (requestsSucceededComplete === pendingRequestsCount) {
        popSnackBar('Audits synchronisés.', 'success')
      } else if (requestsFailedComplete === pendingRequestsCount) {
        popSnackBar(
          'Une erreur a empêché la synchronisation des audits.',
          'error',
        )
      } else {
        popSnackBar(
          "Un ou plusieurs audit(s) n'ont pas peut être synchronisé(s)",
          'warning',
        )
        notifyFailedSyncAudit(apiCall, popSnackBar)
      }
    }

    const success = (key) => async (data) => {
      try {
        await cache.removeItem(key)
        requestsSucceeded += 1
      } catch (err) {
        console.log('error pending request success', err)
      }
      if (requestsSucceeded + requestsFailed === pendingRequestsCount) {
        complete(requestsSucceeded, requestsFailed)
      }
    }

    const failure = (key) => async (data) => {
      try {
        const item = cache.get(key)
        const auditId = key.split('/')[2]

        requestsFailed += 1

        await cache.removeItem(key)
        await cache.set(`failedRequest/audits/${auditId}`, item)
      } catch (err) {
        console.log('error pending request failure', err)
      }

      if (requestsSucceeded + requestsFailed === pendingRequestsCount) {
        complete(requestsSucceeded, requestsFailed)
      }
    }

    if (pendingRequestsCount > 0) {
      forEach(pendingRequests, (request) => {
        const auditId = request.key.split('/')[2]
        apiCall({
          request: AuditAPI.updateAudit(auditId),
          onSuccess: success(request.key),
          onFailure: failure(request.key),
          body: request.body.data,
        })
      })
    } else {
      popSnackBar('Aucun audit à synchroniser !', 'success')
    }
  })
}

export const attachPendingRequestHandler = (apiCall, popSnackBar) => {
  window.addEventListener('online', () =>
    emptyPendingRequests(apiCall, popSnackBar),
  )
  emptyPendingRequests(apiCall, popSnackBar)
}

export const pendingRequestHandler = (apiCall, popSnackBar) =>
  emptyPendingRequests(apiCall, popSnackBar)
