import React from 'react'

import {
  Dashboard,
  Folder,
  Storage,
  ChevronLeft,
  Edit,
  ViewQuilt,
  CheckCircle,
  Error,
} from '@material-ui/icons'
import cache from 'api/utils/CacheHandler'
import { isDatabasesSupported } from 'utils/indexedDB'

export const MAIN_MENU_ITEMS = [
  {
    label: 'Tableau de bord',
    icon: <Dashboard />,
    url: '/dashboard',
    slug: 'dashboard',
  },
  {
    label: 'Liste des audits',
    icon: <Folder />,
    url: '/audits',
    slug: 'audits',
  },
  {
    label: 'Mémoire locale',
    icon: <Storage />,
    url: '/cache',
    slug: 'cache',
    disabled: !isDatabasesSupported(),
  },
]

export const AUDIT_MENU_ITEMS = [
  {
    label: 'Retour',
    icon: <ChevronLeft />,
    url: '/audits',
  },
  {
    separator: true,
  },
  {
    label: "Résumé de l'audit",
    icon: <ViewQuilt />,
    url: '/audits/:audit_id/dashboard',
    slug: 'dashboard',
  },
  {
    label: "Modifier l'audit",
    icon: <Edit />,
    url: '/audits/:audit_id/edit',
    slug: 'edit',
    disabled: false,
    sub_items: [
      {
        label: 'Général',
        url: '/audits/:audit_id/edit/general',
        slug: 'general',
        disabled: false,
      },
      {
        label: 'Localisation',
        url: '/audits/:audit_id/edit/location',
        slug: 'location',
        disabled: false,
      },
      {
        label: 'Accessibilité générale',
        url: '/audits/:audit_id/edit/general-accessibility',
        slug: 'accessibility',
        disabled: false,
      },
      {
        label: "Référentiel d'accessibilité",
        url: '/audits/:audit_id/edit/referential',
        slug: 'referential',
        disabled: false,
      },
      {
        label: 'Galeries photos',
        url: '/audits/:audit_id/edit/photos',
        slug: 'photos',
        disabled: false,
      },
    ],
  },
  {
    separator: true,
  },
  {
    label: "Commencer l'audit",
    icon: <CheckCircle />,
    url: '/audits/:audit_id/start',
    disabled: false,
    predicate_id: 'start',
    subject: 'Audit',
    async: false,
    predicate: (audit) => audit.status === 'not_submitted',
  },
  {
    label: "Terminer l'audit",
    icon: <CheckCircle />,
    url: '/audits/:audit_id/submit',
    disabled: false,
    predicate_id: 'submit',
    subject: 'Audit',
    async: false,
    predicate: (audit) => audit.status !== 'not_submitted',
  },
  {
    separator: true,
  },
  {
    label: "Marquer l'erreur comme résolue",
    icon: <Error />,
    url: '/audits/:audit_id/marked_as_resolved',
    disabled: false,
    subject: 'Audit',
    predicate_id: 'marked_as_resolved',
    async: true,
    predicate: (audit) => cache.isAuditFailedRequest(audit.id),
  },
]
