/* eslint-disable camelcase */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, makeStyles, createStyles } from '@material-ui/core'
import WarningPanel from 'components/WarningPanel'

const useStyles = makeStyles(() =>
  createStyles({
    textCenter: {
      textAlign: 'center',
    },
  }),
)

const BrowserWarning = () => {
  const { t } = useTranslation('atoms')
  const classes = useStyles()

  return (
    <WarningPanel>
      <Typography variant="h6" className={classes.textCenter}>
        {t('atoms:BrowserWarning.text')}
      </Typography>
    </WarningPanel>
  )
}

export default BrowserWarning
