import {
  SET_LOADING_COMPLETE,
  SET_AUDIT_FOR_FORM,
  SET_TAGS,
  SET_PICTOS,
  CLEAR_ALL,
  SET_CATEGORIES,
} from '../constants'

const initialState = {
  categories: [],
  pictos: [],
  audit: {},
  tags: [],
  loadingComplete: false,
}

const cardEdit = (state = initialState, action = { type: '' }) => {
  switch (action.type) {
    case SET_LOADING_COMPLETE:
      return {
        ...state,
        loadingComplete: action.payload.loadingComplete,
      }
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      }

    case SET_AUDIT_FOR_FORM:
      return {
        ...state,
        audit: action.payload.audit,
      }
    case SET_TAGS:
      return {
        ...state,
        tags: action.payload.tags,
      }
    case SET_PICTOS:
      return {
        ...state,
        pictos: action.payload.pictos,
      }
    case CLEAR_ALL:
      return initialState
    default:
      return state
  }
}

export default cardEdit
