/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Navbar from 'components/Navbar'
import AuditIndex from 'components/AuditIndex'
import { getDisplayTitle } from 'helpers/formatter'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}))

const Dashboard = ({ audit }) => {
  const classes = useStyles()
  const { t } = useTranslation('routes')

  return (
    <div className={classes.root}>
      <Navbar
        name={t('routes:audit.dashboard.name', {
          title: getDisplayTitle(audit),
        })}
      />
      <AuditIndex audit={audit} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  audit: state.control.audit,
})

export default connect(mapStateToProps, null)(Dashboard)
