import {
  SET_ADDRESS_PREVIEW_VALUES,
  SET_VALUE_FOR_ADDRESS_PREVIEW,
  PREVIEW_STREETVIEW_FROM_ADDRESS,
  SET_LAT_LNG_FOR_ADDRESS_PREVIEW,
  SET_STREETVIEW_EDITOR_VALUES,
} from '../constants'

const initalState = {
  addressPreview: {
    address: '',
    postalCode: '',
    displayMap: true,
    city: '',
    lat: '',
    lng: '',
  },
  streetViewEditor: {
    lat: 48.859122,
    lng: 2.293164,
    streetView: {
      heading: 142.84091820871282,
      pitch: 26.245302132353842,
      zoom: 0,
      pano: 'CAoSLEFGMVFpcE1MM2p0WF84UFhWZjktMVNqMEFWQVVVbVRRUUVTT0JZSnpBRHFu',
    },
  },
}

const LocationForm = (state = initalState, action = { type: '' }) => {
  switch (action.type) {
    case SET_ADDRESS_PREVIEW_VALUES:
      return {
        ...state,
        addressPreview: action.payload,
      }
    case SET_VALUE_FOR_ADDRESS_PREVIEW:
      return {
        ...state,
        addressPreview: {
          ...state.addressPreview,
          [action.payload.key]: action.payload.value,
        },
      }
    case SET_LAT_LNG_FOR_ADDRESS_PREVIEW:
      return {
        ...state,
        addressPreview: {
          ...state.addressPreview,
          lat: action.payload.lat,
          lng: action.payload.lng,
        },
      }
    case PREVIEW_STREETVIEW_FROM_ADDRESS:
      return {
        ...state,
        streetViewEditor: {
          ...state.streetViewEditor,
          lat: state.addressPreview.lat,
          lng: state.addressPreview.lng,
        },
      }
    case SET_STREETVIEW_EDITOR_VALUES:
      return {
        ...state,
        streetViewEditor: action.payload,
      }
    default:
      return state
  }
}

export default LocationForm
