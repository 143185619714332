import { compact, forEach } from 'lodash'
import i18n from 'env/i18n'
import { BREADCRUMB_LABELS } from './constants'

const t = i18n.getFixedT(null, 'commons')

export const formatBreadcrumb = (pathname) => {
  const results = []
  const urlSplitted = compact(pathname.split('/'))
  let urlRecomposed = ''

  forEach(urlSplitted, (el) => {
    urlRecomposed += `/${el}`
    if (BREADCRUMB_LABELS[el] !== false) {
      if (BREADCRUMB_LABELS[el] !== undefined) {
        results.push({
          label: BREADCRUMB_LABELS[el],
          url: urlRecomposed,
        })
      } else if (el.startsWith('accessibility-')) {
        results.push({
          label: t('commons:label.pictureGalleriesAccessibility'),
          url: urlRecomposed,
        })
      } else if (el.startsWith('page-')) {
        results.push({
          label: t('commons:label.pictureGalleriesPage'),
          url: urlRecomposed,
        })
      } else if (el !== 'all') {
        results.push({ label: el, url: urlRecomposed })
      }
    }
  })
}
