/* eslint-disable import/no-anonymous-default-export */
import atoms from './atoms.json'
import routes from './routes.json'
import services from './services.json'
import commons from './commons.json'

export default {
  atoms,
  routes,
  services,
  commons,
}
