import {
  SET_AUDITS_STATISTICS,
  SET_LOADING,
} from 'services/AuditMissionDashboard/constants'

const initialState = {
  audits_statistics: {},
  loading: true,
}

const auditMissionDashboard = (state = initialState, action = { type: '' }) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      }
    case SET_AUDITS_STATISTICS:
      return {
        ...state,
        audits_statistics: action.payload.audits_statistics,
      }
    default:
      return state
  }
}

export default auditMissionDashboard
