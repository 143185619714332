import React from 'react'
import { Typography, Card, CardContent } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    margin: {
      marginBottom: 10,
    },
    title: {
      fontSize: '2em',
    },
  }),
)

const Navbar = ({ name }) => {
  const classes = useStyles()

  return (
    <Card className={classes.margin}>
      <CardContent className={classes.root}>
        <Typography
          className={classes.title}
          component="h2"
          color="textPrimary"
          align="left"
        >
          {name}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default Navbar
