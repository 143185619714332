export const SET_ADDRESS_PREVIEW_VALUES =
  'LocationForm/SET_ADDRESS_PREVIEW_VALUES'
export const SET_VALUE_FOR_ADDRESS_PREVIEW =
  'LocationForm/SET_VALUE_FOR_ADDRESS_PREVIEW'
export const PREVIEW_STREETVIEW_FROM_ADDRESS =
  'LocationForm/PREVIEW_STREETVIEW_FROM_ADDRESS'
export const SET_LAT_LNG_FOR_ADDRESS_PREVIEW =
  'LocationForm/SET_LAT_LNG_FOR_ADDRESS_PREVIEW'
export const SET_STREETVIEW_EDITOR_VALUES =
  'LocationForm/SET_STREETVIEW_EDITOR_VALUES'
