/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as Control from 'actions/Control'
import * as AuditMissionAPI from 'api/audit_mission'
import { useApiContext } from 'contexts/api'
import { useAuthContext } from 'contexts/auth'

const mapDispatchToProps = (dispatch) => ({
  popSnackbar: (message, type) => dispatch(Control.popSnackbar(message, type)),
  setAuditMission: (auditMission) =>
    dispatch(Control.setAuditMission(auditMission)),
})

const withAuditMissionAuthorization = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { popSnackbar, setAuditMission } = props
    const { apiCall } = useApiContext()
    const { logged, isLogged } = useAuthContext()
    const [state, setState] = useState({
      pendingAuthorization: true,
      authorized: false,
      loadingAuditMission: true,
    })
    const { authorized, pendingAuthorization, loadingAuditMission } = state

    const hasSuffisantRole = (data) => data.active

    const callAPI = () => {
      const success = (data) => {
        if (hasSuffisantRole(data)) {
          setState((prevState) => ({
            ...prevState,
            authorized: true,
            pendingAuthorization: false,
            loadingAuditMission: false,
          }))
          setAuditMission(data)
        } else {
          setState((prevState) => ({
            ...prevState,
            pendingAuthorization: false,
            loadingAuditMission: false,
          }))
          popSnackbar("Cette mission n'est plus disponible", 'error')
        }
      }

      const failure = () => {
        setState((prevState) => ({
          ...prevState,
          pendingAuthorization: false,
          loadingAuditMission: false,
        }))
        popSnackbar(
          'Une erreur est survenue lors du chargement de la mission.',
          'error',
        )
      }

      apiCall({
        request: AuditMissionAPI.getAuditMission(),
        onSuccess: success,
        onFailure: failure,
      })
    }

    useEffect(() => {
      if (isLogged) callAPI()
      else
        setState((prevState) => ({
          ...prevState,
          loadingAuditMission: false,
        }))

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logged])

    return (
      <WrappedComponent
        {...props}
        authorized={authorized}
        pendingAuthorization={pendingAuthorization}
        loadingAuditMission={loadingAuditMission}
      />
    )
  }

  const connectedWrapper = connect(null, mapDispatchToProps)(Wrapper)
  return connectedWrapper
}

export default withAuditMissionAuthorization
