/* eslint-disable camelcase */
import React from 'react'
import {
  PlayCircleFilledWhite,
  PlaylistAddCheck,
  Map,
  Photo,
  Accessibility,
  ChromeReaderMode,
  Check,
  Visibility,
} from '@material-ui/icons'
import { getPictoAccessUrl } from 'api/utils'
import i18n from 'env/i18n'

const t = i18n.getFixedT(null, 'atoms')

export const getIndexButtonConfig = (audit_id) => [
  {
    text: t('atoms:AuditIndex.ButtonLabel.start'),
    icon: <PlayCircleFilledWhite fontSize="large" />,
    url: `/audits/${audit_id}/start`,
    feature_slug: 'start',
  },
  {
    text: t('atoms:AuditIndex.ButtonLabel.general'),
    icon: <PlaylistAddCheck fontSize="large" />,
    url: `/audits/${audit_id}/edit/general`,
    feature_slug: 'general',
  },
  {
    text: t('atoms:AuditIndex.ButtonLabel.referential'),
    icon: <ChromeReaderMode fontSize="large" />,
    url: `/audits/${audit_id}/edit/referential`,
    feature_slug: 'referential',
  },
  {
    text: t('atoms:AuditIndex.ButtonLabel.accessibility'),
    icon: <Accessibility fontSize="large" />,
    url: `/audits/${audit_id}/edit/general-accessibility`,
    feature_slug: 'accessibility',
  },
  {
    text: t('atoms:AuditIndex.ButtonLabel.location'),
    icon: <Map fontSize="large" />,
    url: `/audits/${audit_id}/edit/location`,
    feature_slug: 'location',
  },
  {
    text: t('atoms:AuditIndex.ButtonLabel.photos'),
    icon: <Photo fontSize="large" />,
    url: `/audits/${audit_id}/edit/photos`,
    feature_slug: 'photos',
  },
  {
    text: t('atoms:AuditIndex.ButtonLabel.preview'),
    icon: <Visibility fontSize="large" />,
    url: getPictoAccessUrl(`audits/${audit_id}`),
    external: true,
    feature_slug: 'preview',
  },
  {
    text: t('atoms:AuditIndex.ButtonLabel.submit'),
    icon: <Check fontSize="large" />,
    url: `/audits/${audit_id}/submit`,
    feature_slug: 'submit',
  },
]
