export const SET_DRAGGED = 'picture_gallery/SET_DRAGGED'
export const PUSH_PICTURES = 'picture_gallery/PUSH_PICTURES'
export const REINDEX_PICTURES = 'picture_gallery/REINDEX_PICTURES'
export const DELETE_PICTURE = 'picture_gallery/DELETE_PICTURE'
export const SET_PICTURES = 'picture_gallery/SET_PICTURES'
export const SET_CAPTION = 'picture_gallery/SET_CAPTION'
export const SET_DESCRIPTION = 'picture_gallery/SET_DESCRIPTION'
export const SET_INDEX_DRAGGED = 'picture_gallery/SET_INDEX_DRAGGED'
export const SET_BUSY = 'picture_gallery/SET_BUSY'
export const SET_MAXIMUM_PICTURES = 'picture_gallery/SET_MAXIMUM_PICTURES'
export const SET_PICTURE_GALLERY = 'picture_gallery/SET_PICTURE_GALLERY'
export const SET_MODAL_OPEN = 'picture_gallery/SET_MODAL_OPEN'
export const SET_MODAL_PICTURE = 'picture_gallery/SET_MODAL_PICTURE'

export const PICTURE_GALLERY_TYPE_PAGE = 1
export const PICTURE_GALLERY_TYPE_ACCESSIBILITY = 2
