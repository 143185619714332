import { createContext, useContext, useState, useEffect } from 'react'
import { createMainStorage } from 'store/forage'

const isExpiredToken = (timestamp) =>
  timestamp < new Date().getTime() - 1000 * 60 * 60 * 24 * 7

const saveTokenAndAuditMissionInLocalStorage = (data) => {
  localStorage.setItem(
    'authToken',
    JSON.stringify({
      value: data.token,
      timestamp: new Date().getTime(),
    }),
  )

  localStorage.setItem(
    'auditMission',
    JSON.stringify({
      ...data,
      timestamp: new Date().getTime(),
    }),
  )
}

const initTokenFromDB = (setToken, setLogged) => {
  const authToken = localStorage.getItem('authToken')
  const parsedToken = JSON.parse(authToken)
  if (!parsedToken || isExpiredToken(parsedToken.timestamp)) {
    setLogged('not_logged')
  } else {
    setToken(parsedToken.value)
    setLogged('logged')
    createMainStorage(parsedToken.value)
  }
}

const defaultLogoutCallback = () => window.location.assign('/')

export const useAuthInit = () => {
  const [token, setToken] = useState(null)
  const [logged, setLogged] = useState('pending')

  useEffect(() => {
    initTokenFromDB(setToken, setLogged)
  }, [])

  const logIn = (data) => {
    saveTokenAndAuditMissionInLocalStorage(data)
    initTokenFromDB(setToken, setLogged)
  }
  const logOut = (callback = defaultLogoutCallback) => {
    setToken(null)
    setLogged('not_logged')
    localStorage.clear()
    callback()
  }

  const logPending = logged === 'pending'
  const notLogged = !logPending && logged === 'not_logged'
  const isLogged = !logPending && logged === 'logged'

  return {
    token,
    logged,
    logPending,
    notLogged,
    isLogged,
    setToken,
    logIn,
    logOut,
  }
}

export const AuthContext = createContext()

export function useAuthContext() {
  return useContext(AuthContext)
}
