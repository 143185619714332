/* eslint-disable */
import Loadable from 'react-loadable'
import Loader from 'components/Loader'

export const CardEdit = Loadable({
  loader: () =>
    import(
      'services/CardEdit/components/CardEdit' /* webpackChunkName: "pa-CardEdit" */
    ),
  loading: Loader,
})

export const AuditList = Loadable({
  loader: () =>
    import(
      'services/AuditList/components/AuditList' /* webpackChunkName: "pa-AuditList" */
    ),
  loading: Loader,
})

export const CoverPictureEditor = Loadable({
  loader: () =>
    import(
      'services/CoverPictureEditor' /* webpackChunkName: "pa-CoverPictureEditor" */
    ),
  loading: Loader,
})

export const GeneralAccessibilityForm = Loadable({
  loader: () =>
    import(
      'services/GeneralAccessibilityForm' /* webpackChunkName: "pa-GeneralAccessibilityForm" */
    ),
  loading: Loader,
})

export const LocationForm = Loadable({
  loader: () =>
    import(
      'services/LocationForm/components' /* webpackChunkName: "pa-LocationForm" */
    ),
  loading: Loader,
})

export const PictureGalleries = Loadable({
  loader: () =>
    import(
      'services/PictureGalleries/components/PictureGalleries' /* webpackChunkName: "pa-PictureGalleries" */
    ),
  loading: Loader,
})

export const PictureGallery = Loadable({
  loader: () =>
    import(
      'services/PictureGallery/components/PictureGallery' /* webpackChunkName: "pa-PictureGallery" */
    ),
  loading: Loader,
})

export const ReferentialForm = Loadable({
  loader: () =>
    import(
      'services/AccessibilityReferentialForm' /* webpackChunkName: "pa-AccessibilityReferentialForm" */
    ),
  loading: Loader,
})

export const AuditMissionDashboard = Loadable({
  loader: () =>
    import(
      'services/AuditMissionDashboard/components/AuditMissionDashboard' /* webpackChunkName: "pa-AuditMissionDashboard" */
    ),
  loading: Loader,
})

export const CacheDownloadManager = Loadable({
  loader: () =>
    import(
      'services/CacheDownloadManager' /* webpackChunkName: "pa-CacheDownloadManager" */
    ),
  loading: Loader,
})

export const StorageManager = Loadable({
  loader: () =>
    import(
      'services/StorageManager' /* webpackChunkName: "pa-StorageManager" */
    ),
  loading: Loader,
})
