/* eslint-disable import/no-anonymous-default-export */
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { connectRouter } from 'connected-react-router'
import services from './services'
import control from './control'

export default (history) =>
  combineReducers({
    form: formReducer,
    router: connectRouter(history),
    control,
    services,
  })
