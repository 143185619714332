/* eslint-disable no-unused-vars */
import { join } from 'lodash'
import { push } from 'connected-react-router'
import { popSnackbar } from 'actions/Control'

export const getUrl = () => window.env.REACT_APP_API_URL

export const getAssetUrl = () => {
  let url = getUrl()

  if (
    process.env.NODE_ENV !== 'development' ||
    window.env.REACT_APP_API_URL.includes('pictoaccess.fr/')
  ) {
    url = 'https:'
  }
  return url
}

export const getPictoAccessUrl = (url) =>
  `${window.env.REACT_APP_PICTOACCESS_URL}/${url}`

export const isSuccessful = (status) => status >= 200 && status < 300
export const hasContent = (status) => status !== 204
export const isTokenExpired = (status) => status === 498
export const isUnauthorized = (status) => status === 401
export const isInternalServerError = (status) => status === 500

const displayInternalServerError = (data) => {
  const message = `SERVER ERROR : \n${data.message}\n${join(
    data.backtrace,
    '\n',
  )}`
  console.error(message)
}

export const handleResponseStatus = async (response) => {
  let data = null

  if (hasContent(response.status)) {
    data = await response.json().catch((error) => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error)
      }
    })

    if (isInternalServerError(response.status)) {
      if (process.env.NODE_ENV === 'development') {
        displayInternalServerError(data)
      }
    }
  }

  return Promise.all([response.status, data])
}

export const resolverWrapper = (callbacks, dispatch) => {
  let finalCallbacks = callbacks
  const defaultFailure = (_) => {
    dispatch(popSnackbar('Une erreur est survenue', 'error'))
  }

  const defaultUnauthorized = (_) => {
    dispatch(push('/'))
    dispatch(
      popSnackbar("Vous n'êtes pas autorisé à consulter cette page", 'warning'),
    )
  }

  const tokenExpired = () => {
    localStorage.clear()
    dispatch(push('/'))
    dispatch(popSnackbar('Votre session a expiré', 'warning'))
  }
  if (finalCallbacks.failure === undefined) {
    finalCallbacks = { ...finalCallbacks, failure: defaultFailure }
  }

  if (finalCallbacks.unauthorized === undefined) {
    finalCallbacks = { ...finalCallbacks, unauthorized: defaultUnauthorized }
  }

  return ([status, data]) => {
    if (isSuccessful(status)) {
      finalCallbacks.success(data)
    } else if (isTokenExpired(status)) {
      tokenExpired()
    } else if (isUnauthorized(status)) {
      finalCallbacks.unauthorized(data)
    } else {
      finalCallbacks.failure(data)
    }
  }
}
