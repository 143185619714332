import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Snackbar, Button, makeStyles } from '@material-ui/core'
import { get } from 'lodash'
import ShareAppleIcon from './ShareAppleIcon'

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 99,
    bottom: 0,
    position: 'sticky',
  },
  snackbar: {
    width: '100%',
    borderRadius: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  snackbarContent: {
    width: '100%',
    borderRadius: 0,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  paddingRight: { paddingRight: 15 },
  messageRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
}))

const SnackbarMessage = () => {
  const classes = useStyles()
  const { t } = useTranslation('atoms')

  return (
    <div className={classes.messageRoot}>
      <span id="snackbar-install-app">
        {t('atoms:AddToHomeScreenSnackbarMessage.useIcon')}
      </span>
      <ShareAppleIcon htmlColor="#FFFFFF" />
      <span id="snackbar-install-app">
        {t('atoms:AddToHomeScreenSnackbarMessage.toInstallApp')}
      </span>
    </div>
  )
}

const SnackbarButton = ({ handleClose }) => {
  const classes = useStyles()
  const { t } = useTranslation('commons')

  return (
    <Button
      variant="contained"
      className={classes.menuButton}
      size="small"
      color="primary"
      onClick={handleClose}
    >
      {t('commons:actions.close')}
    </Button>
  )
}

const AddToHomeScreen = () => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  useEffect(() => {
    const userAgent = get(window, 'navigator.userAgent', '')
    const isIos = /iphone|ipad|ipod/.test(userAgent.toLowerCase())
    const isInStandaloneMode =
      'standalone' in window.navigator && window.navigator.standalone

    setOpen(isIos && !isInStandaloneMode)
  }, [])

  const handleClose = () => setOpen(false)

  return open ? (
    <div className={classes.root}>
      <Snackbar
        open={open}
        onClose={null}
        ContentProps={{
          'aria-describedby': 'snackbar-install-app',
          className: classes.snackbarContent,
        }}
        message={<SnackbarMessage />}
        action={<SnackbarButton handleClose={handleClose} />}
        className={classes.snackbar}
      />
    </div>
  ) : null
}

export default AddToHomeScreen
