/* eslint-disable camelcase */
import React from 'react'
import { Button } from '@material-ui/core'
import { push as pushAction } from 'connected-react-router'
import { connect } from 'react-redux'
import { makeStyles, createStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  }),
)

const AuditSubmitButtonBack = ({ push, audit_id }) => {
  const classes = useStyles()

  const handleBack = () => {
    push(`/audits/${audit_id}/dashboard`)
  }

  return (
    <Button
      className={classes.button}
      onClick={handleBack}
      color="secondary"
      variant="contained"
    >
      Retour
    </Button>
  )
}

const mapDispatchToProps = (dispatch) => ({
  push: (url) => {
    dispatch(pushAction(url))
  },
})

export default connect(null, mapDispatchToProps)(AuditSubmitButtonBack)
