/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-constructed-context-values */

import React, { createContext, useContext } from 'react'
import { join as _join, map as _map } from 'lodash'
import { configureSentryScope } from 'sentry'
import resolverWrapper from '../api/utils/resolverWrapper'
import offlineResolverWrapper from '../api/utils/offlineResolverWrapper'

const getUrl = () => `${window.env.REACT_APP_API_URL}/`

const getAuth = () => window.env.REACT_APP_API_AUTH

const getAuthToken = () => {
  configureSentryScope()

  const token = localStorage.getItem('authToken')
  const parsedToken = JSON.parse(token)
  return parsedToken ? parsedToken.value : null
}

export const getHeaders = ({
  namespace = 'picto-audit',
  auditMissionToken = getAuthToken(),
} = {}) => ({
  Accept: `application/vnd.${namespace}-v2+json`,
  'Accept-Language': 'fr',
  'X-Audit-Mission-Token': auditMissionToken,
  'Content-Type': 'application/json',
  Authorization: getAuth(),
})

const hasContent = (status) => status !== 204

const isInternalServerError = (status) => status === 500

const displayInternalServerError = (data) => {
  const message = `SERVER ERROR : \n${data.message}\n${_join(
    data.backtrace,
    '\n',
  )}`
  console.error(message)
}

const handleResponseStatus = async (response) => {
  let data = null

  if (hasContent(response.status)) {
    data = await response.json().catch((error) => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error)
      }
    })

    if (isInternalServerError(response.status)) {
      if (process.env.NODE_ENV === 'development') {
        displayInternalServerError(data)
      }
    }
  }

  return Promise.all([response.status, data])
}

const formatParams = (params) => {
  if (!params) {
    return ''
  }

  let res = '?'
  const pairs = _map(params, (value, key) => {
    if (Array.isArray(value)) {
      return _map(value, (val) => `${key}=${val}`).join('&')
    }
    return `${key}=${value}`
  })

  res += pairs.join('&')
  return res
}

const ApiProvider = ({ dispatch, children }) => {
  const apiCall = ({
    request,
    onSuccess,
    onFailure,
    onUnauthorized,
    params,
    body,
  }) => {
    const url = getUrl() + request.suffix + formatParams(params)
    const attributes = {
      headers: request.headers ? request.headers : getHeaders(),
      method: request.method ? request.method : 'GET',
    }

    if (body) {
      attributes.body = JSON.stringify(body)
    }

    if (!navigator.onLine && request.cachable) {
      if (process.env.NODE_ENV === 'development') {
        console.groupCollapsed(`💾 ${attributes.method} ${url} (CACHED)`)
        console.table(request)
        console.groupEnd()
      }

      return offlineResolverWrapper(
        request,
        attributes,
        {
          success: onSuccess,
          failure: onFailure,
        },
        dispatch,
      )
    }
    if (process.env.NODE_ENV === 'development') {
      console.groupCollapsed(`🌐 ${attributes.method} ${url}`)
      console.table(attributes.headers)
      console.log('BODY : ', body || 'no-content')
      console.groupEnd()
    }
    return fetch(url, attributes)
      .then(handleResponseStatus)
      .then(
        resolverWrapper(
          request,
          {
            success: onSuccess,
            failure: onFailure,
            unauthorized: onUnauthorized,
          },
          dispatch,
        ),
      )
  }

  return (
    <ApiContext.Provider value={{ apiCall }}>{children}</ApiContext.Provider>
  )
}

export const withApi = (Component) => (props) =>
  (
    <ApiContext.Consumer>
      {(store) => <Component {...props} {...store} />}
    </ApiContext.Consumer>
  )

export const ApiContext = createContext()

export function useApiContext() {
  return useContext(ApiContext)
}

export default ApiProvider
