import React from 'react'
import Navbar from 'components/Navbar'
import { AuditMissionDashboard } from 'services'

const Dashboard = () => (
  <>
    <Navbar name="Tableau de bord" />
    <AuditMissionDashboard />
  </>
)

export default Dashboard
