export const SET_LOADING = 'AuditList/SET_LOADING'
export const SET_AUDITS = 'AuditList/SET_AUDITS'
export const SET_FILTERED_AUDITS = 'AuditList/SET_FILTERED_AUDITS'
export const SET_PER_PAGE = 'AuditList/SET_PER_PAGE'
export const SET_PAGE = 'AuditList/SET_PAGE'
export const SET_SEARCH_TERM = 'AuditList/SET_SEARCH_TERM'
export const SET_SELECTED_AUDIT = 'AuditList/SET_SELECTED_AUDIT'
export const SUBMIT_AUDIT = 'AuditList/SUBMIT_AUDIT'
export const START_AUDIT = 'AuditList/START_AUDIT'
export const SEE_AUDIT = 'AuditList/SEE_AUDIT'
