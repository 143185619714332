import i18n from 'env/i18n'
import { popSnackbar } from 'actions/Control'
import * as AuditMissionAPI from 'api/audit_mission'
import { getHeaders } from 'contexts/api'

const t = i18n.getFixedT(null, 'services')

export const handleSignInSubmit =
  (apiCall, token, resetLoadingCallback, logIn) => (dispatch) => {
    const success = (data) => {
      resetLoadingCallback()
      if (data.active === true) {
        logIn(data)
        dispatch(
          popSnackbar(
            t('services:SignInForm.actions.handleSignInSubmit.notice.success'),
            'success',
          ),
        )
        window.location.assign('/')
      } else {
        dispatch(
          popSnackbar(
            t(
              'services:SignInForm.actions.handleSignInSubmit.notice.auditMissionNotActive',
            ),
            'warning',
          ),
        )
      }
    }

    const failure = () => {
      resetLoadingCallback()
      dispatch(
        popSnackbar(
          t('services:SignInForm.actions.handleSignInSubmit.notice.error'),
          'error',
        ),
      )
    }

    apiCall({
      request: {
        headers: getHeaders({ auditMissionToken: token }),
        ...AuditMissionAPI.getAuditMission(),
      },
      params: { normalize: true },
      onSuccess: success,
      onFailure: failure,
    })
  }
