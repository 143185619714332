import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Snackbar, Button } from '@material-ui/core'

const ActionButton = ({ handleOk, handleCancel }) => {
  const { t } = useTranslation('commons')

  return [
    <Button key="0" color="secondary" size="small" onClick={handleCancel}>
      {t('commons:actions.cancel')}
    </Button>,
    <Button key="1" color="secondary" size="small" onClick={handleOk}>
      {t('commons:actions.ok')}
    </Button>,
  ]
}
const UpdateNotification = ({ registrationSkipWaiting }) => {
  const { t } = useTranslation('atoms')
  const [open, setOpen] = useState(true)

  const handleOk = () => {
    registrationSkipWaiting()
    setOpen(false)
  }

  const handleCancel = () => setOpen(false)

  const message = (
    <span id="message-id">
      {t('atoms:UpdateNotification.message_text_1')}
      <br />
      {t('atoms:UpdateNotification.message_text_2')}
    </span>
  )

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      ContentProps={{
        'aria-describedby': 'message-id',
        role: 'alert',
      }}
      message={message}
      action={<ActionButton handleOk={handleOk} handleCancel={handleCancel} />}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    />
  )
}

export default UpdateNotification
