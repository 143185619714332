/* eslint-disable no-console */
import moment from 'moment'
import { filter } from 'lodash'
import { getMainStorage } from 'store/forage'

const cache = {}

const createUpdateEvent = () => {
  const event = document.createEvent('Event')
  event.initEvent('DataStorageChanged', true, true)
  document.dispatchEvent(event)
}

cache.setItem = async (key, value) => {
  try {
    await getMainStorage().setItem(key, { data: value, timestamp: new Date() })
    createUpdateEvent()
  } catch (e) {
    console.warn(
      `cache didn't successfully save the {${key}: ${value}} pair, because the localStorage is full.`,
    )
  }
}
cache.set = cache.setItem

cache.getItem = async (key, missing = null) => {
  let value = null
  try {
    value = await getMainStorage().getItem(key)
  } catch (e) {
    value = null
  }

  if (value) {
    if (
      !value.timestamp ||
      moment(value.timestamp).add(1, 'weeks') < moment()
    ) {
      value = null
      await getMainStorage().removeItem(key)
    }
  }

  if (
    value !== null &&
    typeof value === 'object' &&
    typeof value.data !== 'undefined'
  ) {
    return value.data
  }
  return missing
}

cache.get = cache.getItem

cache.removeItem = async (key) => {
  await getMainStorage().removeItem(key)
  createUpdateEvent()
}

cache.delete = cache.removeItem
cache.remove = cache.removeItem

cache.hasKey = async (key) => {
  try {
    const result = await getMainStorage().getItem(key)
    return result !== null
  } catch (err) {
    console.log('hasKey error', err)
  }
  return null
}

cache.countItemsStartsBy = async (type) => {
  try {
    const allKeys = await getMainStorage().keys()
    const allKeysStartsWithType = filter(allKeys, (key) => key.startsWith(type))
    return allKeysStartsWithType.length
  } catch (err) {
    console.log('countItemsStartsBy', err)
    return null
  }
}

cache.getItemsStartsBy = (type) =>
  new Promise((resolve) => {
    const items = []
    getMainStorage()
      .iterate((value, key) => {
        if (key.startsWith(type)) {
          items.push({
            key,
            body: value,
          })
        }
      })
      .then(() => resolve(items))
      .catch((err) => {
        console.log('getItemsStartsBy', err)
        resolve([])
      })
  })

cache.clearItemsStartsBy = (type) =>
  new Promise((resolve) => {
    getMainStorage()
      .iterate((value, key) => {
        if (key.startsWith(type)) {
          getMainStorage()
            .removeItem(key)
            .then(() => {
              console.log('removeItem in clearItemsStartsBy')
            })
        }
      })
      .then(() => {
        createUpdateEvent()
        resolve()
      })
      .catch((err) => {
        console.log('getItemsStartsBy', err)
        resolve(null)
      })
  })

cache.countPendingRequests = async () =>
  cache.countItemsStartsBy('pendingRequest')
cache.countfailedRequests = async () =>
  cache.countItemsStartsBy('failedRequest')

cache.getFailedRequests = async () => cache.getItemsStartsBy('failedRequest')
cache.getPendingRequests = async () => cache.getItemsStartsBy('pendingRequest')

cache.clearPendingRequests = async () =>
  cache.clearItemsStartsBy('pendingRequest')
cache.clearFailedRequests = async () =>
  cache.clearItemsStartsBy('failedRequest')

cache.isAuditFailedRequest = async (auditId) => {
  const allKeys = await getMainStorage().keys()
  const allKeysStartsWithType = filter(allKeys, (key) =>
    key.startsWith(`failedRequest/audits/${auditId}`),
  )
  return allKeysStartsWithType.length > 0
}

cache.failedRequestToPendingRequest = (auditId) =>
  new Promise((resolve) => {
    getMainStorage()
      // eslint-disable-next-line consistent-return
      .iterate(async (value, key) => {
        if (key.startsWith(`failedRequest/audits/${auditId}`)) {
          const request = await cache.get(`failedRequest/audits/${auditId}`)
          await cache.delete(`failedRequest/audits/${auditId}`)
          await cache.set(`pendingRequest/audits/${auditId}`, request)

          return true
        }
      })
      .then(() => {
        resolve(true)
      })
      .catch((err) => {
        console.log('failedRequestToPendingRequest', err)
        resolve(null)
      })
  })

cache.reconstructAuditsDataFromAudits = () =>
  new Promise((resolve, reject) => {
    const audits = cache.getItemsStartsBy('initialState/audits/')
    const auditsData = {
      audits: [],
      pagination: { total_count: audits.length },
    }
    getMainStorage()
      .iterate((value, key) => {
        if (key.startsWith('initialState/audits/')) {
          auditsData.audits.push(value.data)
        }
      })
      .then(async () => {
        await cache.set('initialState/audits', auditsData)
        resolve()
      })
      .catch((err) => {
        console.log('reconstructAuditsDataFromAudits', err)
        reject(err)
      })
  })

export default cache
