/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { push as pushAction } from 'connected-react-router'
import { map } from 'lodash'
import { Grid, Paper, Typography, Button, makeStyles } from '@material-ui/core'
import AuditStatusStepper from '../AuditStatusStepper'
import { getIndexButtonConfig } from './helpers'

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center',
  },
  entry: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  btn: {
    width: '100%',
  },
  btnText: {
    marginLeft: theme.spacing(2),
  },
}))

const AuditIndexItem = ({ audit, push, item }) => {
  const { text, external, icon, url, feature_slug } = item

  const classes = useStyles()
  if (
    audit.forbidden_features &&
    audit.forbidden_features.includes(feature_slug)
  ) {
    return null
  }

  const buttonColor = (auditItem) => {
    let color = 'default'
    if (
      (auditItem.status === 'not_submitted' && feature_slug === 'start') ||
      (auditItem.status !== 'not_submitted' && feature_slug === 'submit')
    ) {
      color = 'primary'
    }

    if (feature_slug === 'preview') color = 'secondary'
    return color
  }

  const buttonDisabled =
    (audit.status === 'not_submitted' && feature_slug !== 'start') ||
    (audit.status !== 'not_submitted' && feature_slug === 'start')

  return (
    <Grid item xs={10} className={classes.entry}>
      <Button
        variant="contained"
        color={buttonColor(audit)}
        onClick={() => {
          if (external === true) window.open(item.url, '_blank')
          else push(url)
        }}
        className={classes.btn}
        disabled={buttonDisabled}
      >
        <Typography>{icon}</Typography>
        <Typography className={classes.btnText}>{text}</Typography>
      </Button>
    </Grid>
  )
}

const AuditIndex = ({ push, audit }) => {
  return (
    <Paper>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} sm={12}>
          <AuditStatusStepper audit={audit} />
        </Grid>
        <Grid container item xs={12} sm={12} justifyContent="center">
          {map(getIndexButtonConfig(audit.id), (item) => (
            <AuditIndexItem
              audit={audit}
              push={push}
              key={item.feature_slug}
              item={item}
            />
          ))}
        </Grid>
      </Grid>
    </Paper>
  )
}

const mapStateToProps = (state) => ({
  audit: state.control.audit,
})
const mapDispatchToProps = (dispatch) => ({
  push: (url) => dispatch(pushAction(url)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuditIndex)
