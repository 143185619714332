export const BREADCRUMB_LABELS = {
  list: 'Liste',
  all: 'Liste des audits',
  cache: 'Gestion de la mémoire locale',
  cards: false,
  dashboard: 'Tableau de bord',
  accessibility: 'Accessibilité',
  edit: 'Modifier',
  general: 'Général',
  location: 'Localisation',
  'general-accessibility': 'Accessibilité générale',
  referential: "Référentiel d'accessibilité",
  configuration: 'Configuration',
  parameters: 'Paramètres',
  sign_in: 'Se connecter',
  audits: 'Audits',
  submit: "Terminer l'audit",
  photos: 'Galeries photos',
  cover: 'Photo de couverture',
}
