/* eslint-disable import/no-named-as-default-member */
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import resources from '../locales'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    resources,
    lng: ['fr', 'en'],
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
    parseMissingKeyHandler: (key) => `MISSING_KEY__${key}`,
  })

export default i18n
