import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { withAuditAuthorization } from 'wrappers'
import PrivateRoute from 'components/PrivateRoute'
import PictoLoader from 'components/PictoLoader'
import Dashboard from './routes/Dashboard'
import Edit from './routes/Edit'
import Submit from './routes/Submit'
import Start from './routes/Start'
import MarkedAsResolved from './routes/MarkedAsResolved'

const Audit = ({
  auditAuthorized,
  pendingAuditAuthorization,
  loadingAuditAuthorization,
}) => (
  <>
    {loadingAuditAuthorization && <PictoLoader />}
    <Switch>
      <Redirect
        exact
        from="/audits/:audit_id/"
        to="/audits/:audit_id/dashboard"
      />
      <PrivateRoute
        exact
        path="/audits/:audit_id/dashboard"
        component={Dashboard}
        redirectTo="/"
        authorized={auditAuthorized}
        pendingAuthorization={pendingAuditAuthorization}
      />
      <PrivateRoute
        path="/audits/:audit_id/edit"
        component={Edit}
        redirectTo="/"
        authorized={auditAuthorized}
        pendingAuthorization={pendingAuditAuthorization}
      />
      <PrivateRoute
        path="/audits/:audit_id/marked_as_resolved"
        component={MarkedAsResolved}
        redirectTo="/"
        authorized={auditAuthorized}
        pendingAuthorization={pendingAuditAuthorization}
      />
      <PrivateRoute
        path="/audits/:audit_id/submit"
        component={Submit}
        redirectTo="/"
        authorized={auditAuthorized}
        pendingAuthorization={pendingAuditAuthorization}
      />
      <PrivateRoute
        path="/audits/:audit_id/start"
        component={Start}
        redirectTo="/"
        authorized={auditAuthorized}
        pendingAuthorization={pendingAuditAuthorization}
      />
    </Switch>
  </>
)

export default withAuditAuthorization(Audit)
