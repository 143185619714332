import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { getMainStorage } from 'store/forage'

const configureTokenScope = async () => {
  try {
    const token = await getMainStorage().getItem('authToken')
    const parsedToken = JSON.parse(token)
    if (parsedToken !== null) {
      return parsedToken.value
    }
    return null
  } catch (err) {
    return null
  }
}

export const captureEvent = (err) => {
  Sentry.captureEvent(err)
}

export const configureSentryScope = () => {
  const auditToken = configureTokenScope()
  Sentry.configureScope((scope) => {
    scope.setExtra('auditToken', auditToken)
  })
}

export const initSentry = (props) => {
  const {
    router: { history, routes, matchPath },
  } = props

  const tracesSampleRate = process.env.NODE_ENV === 'development' ? 0.0 : 1.0
  Sentry.init({
    dsn: 'https://f285b00ce08640c7a5b3576858ee7841@sentry.unisc.fr/5',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(
          history,
          routes,
          matchPath,
        ),
      }),
    ],
    environment: process.env.REACT_APP_APP_ENV,
    tracesSampleRate,
    ignoreErrors: ['You are not authorized to access this page'],
    normalizeDepth: 10,
  })
  configureSentryScope()
}
