/* eslint-disable camelcase */
import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import WarningIcon from '@material-ui/icons/Warning'
import amber from '@material-ui/core/colors/amber'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    textCenter: {
      textAlign: 'center',
    },
    warning: {
      backgroundColor: amber[400],
    },
  }),
)

const WarningPanel = ({ className, children }) => {
  const classes = useStyles()

  return (
    <Paper className={`${classes.root} ${classes.warning} ${className}`}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={1} className={classes.textCenter}>
          <WarningIcon fontSize="large" className={classes.warning} />
        </Grid>
        <Grid item xs={12} sm={11}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default WarningPanel
