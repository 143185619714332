import React from 'react'
import { connect } from 'react-redux'
import {
  Toolbar,
  Typography,
  makeStyles,
  createStyles,
} from '@material-ui/core'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { map } from 'lodash'
import { formatBreadcrumb } from './helpers'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.primary.dark,
      flexWrap: 'wrap',
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
    },
    label: {
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    },
    arrow: {
      [theme.breakpoints.down('md')]: {
        fontSize: 18,
      },
    },
  }),
)

const BreadCrumbLinks = ({ pathname }) => {
  const classes = useStyles()

  return map(formatBreadcrumb(pathname), (res, index) => [
    <KeyboardArrowRight
      key={`${res.label}-${index}-key`}
      className={classes.arrow}
    />,
    <a className={classes.link} href={res.url} key={`${res.label}-key`}>
      <Typography variant="subtitle1" color="inherit" className={classes.label}>
        {res.label}
      </Typography>
    </a>,
  ])
}

const Breadcrumb = (props) => {
  const { pathname } = props
  const classes = useStyles()

  return (
    <Toolbar className={classes.container} variant="dense">
      <BreadCrumbLinks pathname={pathname} />
    </Toolbar>
  )
}

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
})

export default connect(mapStateToProps, null)(Breadcrumb)
