/* eslint-disable camelcase */
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { push as pushAction } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import { useApiContext } from 'contexts/api'
import * as Control from 'actions/Control'
import * as AuditAPI from 'api/audit'
import { get } from 'lodash'
import { onlyOnline } from 'wrappers'

const AuditStart = ({ audit, popSnackbar, setAudit, push }) => {
  const { apiCall } = useApiContext()
  const { t } = useTranslation(['atoms', 'commons'])

  const hasAuditAlreadyStarted =
    get(audit, 'status', 'in_progress') === 'in_progress'

  useEffect(() => {
    if (hasAuditAlreadyStarted) {
      push(`/audits/${audit.id}/dashboard`)
      return
    }

    const handleStart = () => {
      const onSuccess = (data) => {
        setAudit(data)
        popSnackbar(
          t('atoms:AuditStart.HandleStartOnSuccessMessage'),
          'success',
        )
        push(`/audits/${audit.id}/dashboard`)
      }
      const onFailure = () => popSnackbar(t('commons:notice.error'), 'error')

      const body = {
        audit: {
          status: 'in_progress',
        },
      }

      apiCall({
        request: AuditAPI.updateAudit(audit.id),
        onSuccess,
        onFailure,
        body,
      })
    }
    handleStart()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

const mapDispatchToProps = (dispatch) => ({
  push: (url) => dispatch(pushAction(url)),
  popSnackbar: (msg, type) => dispatch(Control.popSnackbar(msg, type)),
  setAudit: (audit) => dispatch(Control.setAudit(audit)),
})

export default connect(null, mapDispatchToProps)(onlyOnline(AuditStart))
