import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { getAuditIdFromURL } from 'utils/browser'
import { getItemList } from 'helpers/menu'
import OfflineError from 'components/OfflineError'
import AppMenu from 'components/AppMenu'
import Snackbar from 'components/Snackbar'
import Dashboard from 'app/routes/Dashboard'
import AddToHomeScreen from 'components/AddToHomeScreen'
import Audits from 'app/routes/Audits'
import Cache from 'app/routes/Cache'
import { isDatabasesSupported } from 'utils/indexedDB'

const PageForLoggedAndAuthorizedUser = ({
  location: { pathname },
  match: { url },
  snackbar,
  setSnackbar,
}) => (
  <>
    <AppMenu
      canBeOpened={pathname !== '/sign_in'}
      items={getItemList(pathname)}
      audit_id={getAuditIdFromURL(pathname)}
    >
      <Switch>
        <Route path={`${url}audits`} component={Audits} />
        <Route exact path={`${url}dashboard`} component={Dashboard} />
        {isDatabasesSupported() && (
          <Route path={`${url}cache`} component={Cache} />
        )}
        <Route path={`${url}offline_error`} component={OfflineError} />
      </Switch>
      <Snackbar snackbar={snackbar} setSnackbar={setSnackbar} />
    </AppMenu>
    <AddToHomeScreen />
  </>
)

export default withRouter(PageForLoggedAndAuthorizedUser)
