/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Typography, Button } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import { WifiOff } from '@material-ui/icons'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: '80%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      fontSize: 200,
      color: theme.palette.grey[400],
      marginBottom: theme.spacing(4),
    },
    text: {
      color: theme.palette.grey[600],
      marginBottom: theme.spacing(2),
    },
  }),
)

const OfflineError = (props) => {
  const {
    history: { goBack },
  } = props

  const { t } = useTranslation('atoms')
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <WifiOff className={classes.icon} />
      <Typography className={classes.text} variant="h4">
        {t('atoms:OfflineError.text_1')}
      </Typography>
      <Typography className={classes.text} variant="h5">
        {t('atoms:OfflineError.text_2')}
      </Typography>
      <Button variant="contained" onClick={goBack}>
        {t('atoms:OfflineError.button')}
      </Button>
    </div>
  )
}

export default withRouter(OfflineError)
