import { SET_REFERENTIAL } from '../constants'

const initialState = {
  referential: null,
}

const referential = (state = initialState, action = { type: '' }) => {
  switch (action.type) {
    case SET_REFERENTIAL:
      return {
        ...state,
        referential: action.payload,
      }
    default:
      return state
  }
}

export default referential
