import { routerMiddleware } from 'connected-react-router'
import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from 'redux'
import thunk from 'redux-thunk'
import * as Sentry from '@sentry/react'
import createRootReducer from '../reducers'

let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
if (process.env.NODE_ENV === 'production') {
  composeEnhancers = compose
}

const configureStore = (history) => {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer()

  return createStore(
    createRootReducer(history),
    {},
    composeEnhancers(
      applyMiddleware(routerMiddleware(history), thunk),
      sentryReduxEnhancer,
    ),
  )
}

export default configureStore
