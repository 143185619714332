/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable default-case */
/* eslint-disable camelcase */
import React from 'react'
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  withStyles,
} from '@material-ui/core'
import { map } from 'lodash'
import { getActiveStepFromAuditStatus, getAuditsSteps } from './helpers'

const ColorConnector = withStyles(
  (theme) => ({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: theme.palette.primary.main,
      },
    },
    completed: {
      '& $line': {
        borderColor: theme.palette.primary.main,
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }),
  { withTheme: true },
)(StepConnector)

const AuditStatusStepper = ({ audit }) => {
  const activeStep = getActiveStepFromAuditStatus(audit)
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorConnector />}
    >
      {map(getAuditsSteps(audit), (item) => {
        const stepProps = {}
        const labelProps = {}
        let { text } = item

        labelProps.error = false
        if (item.isError) {
          text = item.errorText
          labelProps.error = true
        }
        if (item.hasAlertText && item.step === activeStep) {
          labelProps.optional = (
            <Typography
              variant="caption"
              color={item.isError ? 'error' : 'initial'}
            >
              {item.alertText}
            </Typography>
          )
        }
        return (
          <Step key={text} {...stepProps}>
            <StepLabel {...labelProps} style={{ textAlign: 'center' }}>
              {text}
            </StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}

export default AuditStatusStepper
