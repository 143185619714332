import {
  SET_LOADING,
  SET_AUDITS,
  SET_FILTERED_AUDITS,
  SET_PAGE,
  SET_PER_PAGE,
  SET_SEARCH_TERM,
  SET_SELECTED_AUDIT,
} from 'services/AuditList/constants'

const initialState = {
  audits: [],
  filteredAudits: [],
  page: 0,
  per_page: 50,
  search_term: '',
  loading: true,
  action_menu: {
    selected_audit: null,
    open: false,
    anchorEl: null,
  },
}

const auditList = (state = initialState, action = { type: '' }) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      }
    case SET_AUDITS:
      return {
        ...state,
        audits: action.payload.audits,
      }
    case SET_FILTERED_AUDITS:
      return {
        ...state,
        filteredAudits: action.payload.filteredAudits,
      }
    case SET_SEARCH_TERM:
      return {
        ...state,
        search_term: action.payload.search_term,
      }
    case SET_PAGE:
      return {
        ...state,
        page: action.payload.page,
      }
    case SET_PER_PAGE:
      return {
        ...state,
        per_page: action.payload.per_page,
      }
    case SET_SELECTED_AUDIT:
      return {
        ...state,
        action_menu: action.payload,
      }
    default:
      return state
  }
}

export default auditList
