/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest)
  return React.createElement(component, finalProps)
}

const PrivateRoute = ({ component, redirectTo, ...rest }) => {
  const renderComponent = (routeProps) => {
    if (rest.pendingAuthorization === false) {
      if (rest.authorized === true) {
        return renderMergedProps(component, routeProps, rest)
      }
      return (
        <Redirect
          to={{
            pathname: redirectTo,
            state: { from: routeProps.location },
          }}
        />
      )
    }
    return ''
  }

  return (
    <Route {...rest} render={(routeProps) => renderComponent(routeProps)} />
  )
}

export default PrivateRoute
