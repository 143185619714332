import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import AppMenu from 'components/AppMenu'
import Snackbar from 'components/Snackbar'
import AddToHomeScreen from 'components/AddToHomeScreen'
import SignInForm from 'services/SignInForm'
import { getAuditIdFromURL } from 'utils/browser'
import { getItemList } from 'helpers/menu'

const PageNotLoggedUser = ({
  location: { pathname },
  match: { url },
  snackbar,
  setSnackbar,
}) => (
  <>
    <AppMenu
      canBeOpened={pathname !== '/sign_in'}
      items={getItemList(pathname)}
      audit_id={getAuditIdFromURL(pathname)}
    >
      <Switch>
        <Route exact path={`${url}sign_in`} component={SignInForm} />
      </Switch>
      <Snackbar snackbar={snackbar} setSnackbar={setSnackbar} />
    </AppMenu>
    <AddToHomeScreen />
  </>
)

export default withRouter(PageNotLoggedUser)
