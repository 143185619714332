import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { push as pushAction } from 'connected-react-router'
import {
  Typography,
  TextField,
  Button,
  Grid,
  makeStyles,
} from '@material-ui/core'
import { useApiContext } from 'contexts/api'
import { useAuthContext } from 'contexts/auth'
import BrowserWarning from 'components/BrowserWarning'
import PictoLoader from 'components/PictoLoader'
import { detectBrowser } from 'utils/browser'
import AuditMissionInCacheShortcuts from './AuditMissionInCacheShortcuts'
import { handleSignInSubmit as handleSignInSubmitAction } from './actions'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto auto',
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
    width: '100%',
  },
}))

const SignInForm = ({ handleSignInSubmit, push, location }) => {
  const classes = useStyles()

  const { t } = useTranslation('services')
  const { apiCall } = useApiContext()
  const { logPending, isLogged, logIn } = useAuthContext()
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const [hasError, setError] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const tokenFromParams = params.get('token') || ''
    setToken(tokenFromParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (logPending) return null
  if (isLogged) push('/dashboard')

  const resetLoading = () => {
    setLoading(false)
    setError(true)
  }

  const handleOnSubmit = (event) => {
    event.preventDefault()
    setLoading(true)
    handleSignInSubmit(apiCall, token, resetLoading, logIn)
  }

  return (
    <>
      {detectBrowser() !== 'Chrome' && <BrowserWarning />}
      {loading && <PictoLoader />}
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={3}>
          <form
            className={classes.root}
            autoComplete="off"
            acceptCharset="UTF-8"
            onSubmit={(event) => handleOnSubmit(event)}
          >
            <Typography
              variant="h2"
              component="h2"
              color="textPrimary"
              gutterBottom
              align="center"
            >
              {t('services:SignInForm.connection')}
            </Typography>
            <TextField
              required
              id="token"
              label={t('services:SignInForm.token')}
              value={token}
              onChange={(e) => setToken(e.target.value)}
              className={classes.textField}
              error={hasError}
              margin="normal"
              variant="outlined"
            />

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={loading}
              type="submit"
            >
              {loading
                ? t('services:SignInForm.loading')
                : t('services:SignInForm.connect')}
            </Button>
            <Typography
              className={classes.link}
              href="https://www.pictoaccess.fr/contact"
              component="a"
              target="_blank"
              color="textPrimary"
              variant="body1"
            >
              {t('services:SignInForm.contactUs')}
            </Typography>
            <AuditMissionInCacheShortcuts setToken={setToken} />
          </form>
        </Grid>
      </Grid>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleSignInSubmit: (apiCall, token, resetLoadingCallback, logIn) =>
    dispatch(
      handleSignInSubmitAction(apiCall, token, resetLoadingCallback, logIn),
    ),
  push: (path) => dispatch(pushAction(path)),
})

export default connect(null, mapDispatchToProps)(SignInForm)
