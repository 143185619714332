import { updateObjectInArray, reindexedPictures, orderByIndex } from '../utils'
import {
  PUSH_PICTURES,
  REINDEX_PICTURES,
  DELETE_PICTURE,
  SET_PICTURES,
  SET_CAPTION,
  SET_DESCRIPTION,
} from '../constants'

const defaultState = []

const pictures = (state = defaultState, action = { type: '' }) => {
  switch (action.type) {
    case SET_PICTURES:
      return orderByIndex(action.payload)
    case PUSH_PICTURES:
      return state.concat(action.pictures)
    case SET_CAPTION:
      return updateObjectInArray(
        state,
        action.picture,
        action.caption,
        'caption',
      )
    case SET_DESCRIPTION:
      return updateObjectInArray(
        state,
        action.picture,
        action.description,
        'description',
      )
    case DELETE_PICTURE:
      return updateObjectInArray(state, action.picture, true, '_destroy')
    case REINDEX_PICTURES:
      return reindexedPictures(state)
    default:
      return state
  }
}

export default pictures
