/* eslint-disable camelcase */
import React from 'react'
import { useTranslation } from 'react-i18next'
import Navbar from 'components/Navbar'
import { CoverPictureEditor } from 'services'

const Cover = ({
  match: {
    params: { audit_id },
  },
}) => {
  const { t } = useTranslation('routes')
  return (
    <>
      <Navbar name={t('routes:audit.edit.photos.cover.name')} />
      <CoverPictureEditor audit_id={audit_id} />
    </>
  )
}

export default Cover
