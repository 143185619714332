/* eslint-disable camelcase */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { includes } from 'lodash'
import Navbar from 'components/Navbar'
import { PictureGallery } from 'services'

const Edit = ({
  match: {
    params: { audit_id, picture_gallery_slug },
  },
}) => {
  const { t } = useTranslation('routes')
  const isPictureGalleryPage = includes(picture_gallery_slug, 'page')

  return (
    <>
      <Navbar
        name={
          isPictureGalleryPage
            ? t('routes:audit.edit.photos.edit.name_page')
            : t('routes:audit.edit.photos.edit.name_accessibility')
        }
      />
      <PictureGallery
        maxPictures={10}
        auditID={audit_id}
        pictureGallerySlug={picture_gallery_slug}
      />
    </>
  )
}

export default Edit
