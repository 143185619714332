import {
  SET_DRAGGED,
  SET_BUSY,
  SET_MAXIMUM_PICTURES,
  SET_PICTURE_GALLERY,
  SET_MODAL_OPEN,
  SET_MODAL_PICTURE,
} from '../constants'

const initalState = {
  busy: false,
  dragged: false,
  modalOpen: false,
  currentPictureGallery: {},
  maxPictures: 10,
  errors: [],
  picture: null,
}

const control = (state = initalState, action = { type: '' }) => {
  switch (action.type) {
    case SET_BUSY:
      return {
        ...state,
        busy: action.payload,
      }
    case SET_DRAGGED:
      return {
        ...state,
        dragged: action.payload,
      }
    case SET_MAXIMUM_PICTURES:
      return {
        ...state,
        maxPictures: action.payload,
      }
    case SET_PICTURE_GALLERY:
      return {
        ...state,
        currentPictureGallery: action.payload,
      }
    case SET_MODAL_OPEN:
      return {
        ...state,
        modalOpen: action.payload,
      }
    case SET_MODAL_PICTURE:
      return {
        ...state,
        picture: action.payload,
      }
    default:
      return state
  }
}

export default control
