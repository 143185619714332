import {
  SET_THEME,
  SET_AUDIT,
  SET_AUDIT_MISSION,
  POP_SNACKBAR,
  SET_SNACKBAR,
} from 'constants/ActionTypes'
import { createTheme } from '@material-ui/core/styles'
import { amber } from '@material-ui/core/colors'

const initialState = {
  theme: createTheme({
    typography: {},
    palette: {
      primary: {
        main: '#81c25d',
        light: '#9ace7d',
        dark: '#5a8741',
        contrastText: '#FFF',
      },
      secondary: amber,
      common: {
        yellow: '#FFE500',
        lightYellow: '#FFF067',
        green: '#00FFA3',
        red: '#FF3981',
        blue: '#4DFFFF',
        black: '#000000',
        white: '#FFFFFF',
        greys: {
          veryDark: '#2D2D2D',
          dark: '#464646',
          medium: '#414141',
          mediumLight: '#686868',
          light: '#737373',
          lighter: '#F3F3F3',
          veryLight: '#FCFCFC',
        },
      },
    },
  }),
  snackbar: {
    open: false,
    message: '',
    type: '',
  },
  audit: {},
  auditMission: {},
}

const control = (state = initialState, action = { type: '' }) => {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: createTheme(action.payload),
      }
    case SET_AUDIT:
      return {
        ...state,
        audit: action.payload,
      }
    case SET_AUDIT_MISSION:
      return {
        ...state,
        auditMission: action.payload,
      }
    case POP_SNACKBAR:
      return {
        ...state,
        snackbar: {
          open: true,
          message: action.message,
          type: action.snack_type,
        },
      }
    case SET_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          [action.attribute]: action.value,
        },
      }
    default:
      return state
  }
}

export default control
